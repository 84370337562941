import { UpOutlined } from "@ant-design/icons";
import "./rubricScoresAccordion.css";
import {useEffect, useState, useRef} from "react";

const RubricScoresAccordion = ({ criteriaTitle, scores, criteriaIndex, criteriaUsage,scoreExplanation,rubricHightScore,onClick }) => {
    const [accordionStates, setAccordionStates] = useState({});
    const [content, setContent] = useState(
        scoreExplanation
    );
    const [activeScores, setActiveScores] = useState(scores-1);
    const handleContentChange = (event, index) => {
        setContent(event.target.value);
        criteriaUsage(index,activeScores +1, event.target.value);
    };

    const textAreaRef = useRef({});

    useEffect(() => {
        setContent(scoreExplanation)
        setActiveScores(scores-1)
    }, [scores,scoreExplanation]);
    const toggle = (criteriaIndex) => {
        setAccordionStates((prevState) => ({
            ...prevState,
            [criteriaIndex]: !prevState[criteriaIndex],
        }));
    };

    const handleScoreClick = (scoreIndex) => {
        const newScore = activeScores !== null ? scoreIndex+1 : 0;
        if (scoreIndex === activeScores){
            setActiveScores(null)
        }else{
            setActiveScores(scoreIndex)
        }
        onClick(scoreIndex)
        criteriaUsage(criteriaIndex, newScore, content);
    };

    // Function to resize the textarea dynamically
    const resizeTextarea = (index) => {
        const textarea = textAreaRef.current[index];
        if (textarea) {
            textarea.style.height = 'auto'; // Reset height
            textarea.style.height = `${textarea.scrollHeight}px`; // Adjust to new content
        }
    };

    useEffect(() => {
        resizeTextarea(criteriaIndex); // Resize the textarea when the component mounts
    }, [criteriaIndex]);

    useEffect(() => {
        resizeTextarea(criteriaIndex); // Resize the textarea whenever content changes
    }, [content]);

    return (
        <div className="rubricAccordion">
            <div className="rubricAccordion__title">
                <div
                    className="rubricAccordion__header"
                    onClick={() => toggle(criteriaIndex)}
                >
                    <div className="rubricAccordion__header-content">
                        <h1 className="rubricAccordion__header-title">
                            {criteriaTitle}
                        </h1>
                        <span className="rubricAccordion__header-score">
                            {activeScores !== null ? activeScores+1 : 0}/
                            {rubricHightScore}
                        </span>
                    </div>
                    <UpOutlined
                        className={`rubricAccordion__icon ${
                            accordionStates[criteriaIndex] ? "toggled" : ""
                        }`}
                    />
                </div>
                <div className="rubricAccordion__footer">
                    {Array.from({ length: rubricHightScore }).map((_, scoreIndex) => (
                        <div
                            style={{ width: `${100 / rubricHightScore}%` }}
                            key={scoreIndex}
                            className={`rubricAccordion__footer-score ${
                                (activeScores !== null && activeScores >= scoreIndex) ? "active" : ""
                            }`}
                            onClick={() => handleScoreClick(scoreIndex)}
                        />
                    ))}
                </div>
            </div>
            <div
                className={`rubricAccordion__content ${
                    accordionStates[criteriaIndex] ? "toggled" : ""
                }`}
            >
                <textarea
                    ref={(el) => (textAreaRef.current[criteriaIndex] = el)}
                    onChange={(e)=>handleContentChange(e, criteriaIndex)}
                    value={content}
                />
            </div>
        </div>
    );
};

export default RubricScoresAccordion;