

const ListNum = () => {
    return (
        <svg width="20" height="14" viewBox="0 0 20 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.5 11H2.5V11.5H1.5V12.5H2.5V13H0.5V14H3.5V10H0.5V11ZM1.5 4H2.5V0H0.5V1H1.5V4ZM0.5 6H2.3L0.5 8.1V9H3.5V8H1.7L3.5 5.9V5H0.5V6ZM5.5 1V3H19.5V1H5.5ZM5.5 13H19.5V11H5.5V13ZM5.5 8H19.5V6H5.5V8Z"
                fill="currentColor"/>
        </svg>
    )
}

export default ListNum;