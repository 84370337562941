import { api } from './api';

export const Statistics = api.injectEndpoints({
    endpoints: (builder) => ({

        correctStatistics: builder.mutation({
            query: (data) => ({
                url: '/api/correct',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            }),
            invalidatesTags: [{ type: 'Statistics', id: 'LIST' }],
        }),
    }),
});

export const { useCorrectStatisticsMutation } = Statistics;