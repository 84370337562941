

const BoldIcon = () => {
    return (
        <svg height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.25002 6.8C9.73966 6.50184 10.1529 6.09342 10.4567 5.60729C10.7605 5.12116 10.9466 4.57077 11 4C11.0093 3.48388 10.9168 2.971 10.7278 2.49063C10.5388 2.01027 10.2571 1.57184 9.89864 1.20039C9.54019 0.828938 9.11207 0.531741 8.63874 0.325776C8.16541 0.119811 7.65614 0.00911107 7.14002 0H0.650024V14H7.65002C8.14118 13.9948 8.62649 13.8929 9.07825 13.7001C9.53001 13.5073 9.93937 13.2274 10.283 12.8764C10.6265 12.5253 10.8976 12.1101 11.0807 11.6543C11.2638 11.1985 11.3553 10.7112 11.35 10.22V10.1C11.3504 9.40711 11.1529 8.72852 10.781 8.14395C10.409 7.55939 9.87785 7.09315 9.25002 6.8ZM2.65002 2H6.85002C7.2762 1.98681 7.69621 2.10428 8.0537 2.33665C8.41119 2.56902 8.68906 2.90517 8.85003 3.3C9.0129 3.82779 8.96022 4.39859 8.70351 4.88765C8.4468 5.37672 8.00692 5.74428 7.48002 5.91C7.27541 5.96996 7.06325 6.00027 6.85002 6H2.65002V2ZM7.25002 12H2.65002V8H7.25002C7.6762 7.98681 8.09621 8.10428 8.4537 8.33665C8.81119 8.56902 9.08906 8.90517 9.25002 9.3C9.4129 9.82779 9.36022 10.3986 9.10351 10.8877C8.8468 11.3767 8.40692 11.7443 7.88002 11.91C7.67541 11.97 7.46325 12.0003 7.25002 12Z"
                fill="currentColor"/>
        </svg>
    )
}

export default BoldIcon;