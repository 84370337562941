
import AppLayout from "../Layout/AppLayout";
import Table from "../../Components/table/Table";
import "./Assignment.css"
import React, {useCallback, useEffect, useState} from "react"
import {Button, Dropdown, Input, Spin, Tag} from "antd";
import {SearchOutlined,FormOutlined, DeleteOutlined, DownOutlined, UpOutlined} from "@ant-design/icons";
import NewAssignment from "../../Components/newAssignment/NewAssignment";
import {useNavigate} from "react-router-dom";
import {useLazyGetAssignmentsByTeacherAndClassQuery} from "../../services/assignments.api";
import {
  useCheckStudentCourseworkMutation,
} from "../../services/studentcourseworks.api";
import {useGetMeQuery} from "../../services/auth.api";
import EditAssignment from "../../Components/editAssignment/EditAssignment";
import {useLazyGetCourseWorkQuery, useDeleteCourseWorkMutation,useLazyGetGoogleClassroomCourseWorksQuery} from "../../services/coursworks.api";
import { Modal, message } from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {selectClassroom} from "../../redux/app/app.selectors";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import {selectAuthenticatedUser} from "../../redux/auth/auth.selectors";
import {setNotification} from "../../redux/app/app.slice";

const Remedia = () => {
  const classroom = useSelector(selectClassroom);
  const [searchTerm, setSearchTerm] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [coursWorkData, setCourseWorkData] = useState(null);
  const [trigger] = useCheckStudentCourseworkMutation();
  const [triggerCourseWork, { dataCourseWork, error: errorCourseWork, isLoading: isLoadingCourseWork }] = useLazyGetCourseWorkQuery();
  const [deleteCourseWork] = useDeleteCourseWorkMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: 'id', direction: null });
  const dispatch = useDispatch();
  const [triggerAssignment, {data: assignmentData, isLoading:isLoadingAssignments }] = useLazyGetAssignmentsByTeacherAndClassQuery();

  const navigate = useNavigate();

  const [triggerGoogleClassroomCourseWorks] = useLazyGetGoogleClassroomCourseWorksQuery();
  const user = useSelector(selectAuthenticatedUser);

  const [GradeAndSubjectData, setGradeAndSubjectData] = useState({subject: '', grade: ''});



  useEffect(() => {
    const params = {classroom: classroom.id};
    if(!user.roles.includes('ROLE_TEACHER')){
      const  params = {classroom: classroom.id, state: 'active'};
      triggerAssignment(params);
      return;
    }

    triggerAssignment(params);

  }, [ classroom]);

  useEffect(() => {
    if (!user.roles.includes('ROLE_TEACHER')) {
      return;
    }
    setGradeAndSubjectData({
        subject: classroom.subject,
        grade: classroom.grade
    })
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const addAssisement = () => {
    setIsModalOpen(!isModalOpen);
  };

  const editAssisement = (item) => {

    triggerCourseWork(item.id).then((result) => {
      if (result.data) {
        setCourseWorkData(result.data);
        setIsEditModalOpen(!isEditModalOpen);
      }
    });
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteAssisement = (item) => {
    const { confirm } = Modal;
    confirm({
      title: 'Are you sure you want to delete this coursework?',
      content: `Coursework title: ${item.title}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteCourseWork({ assignmentId: item.id }).then((result) => {
          if (result.data) {
            // triggerAssignment();
            dispatch(
                setNotification({
                  type: 'success',
                  message: 'Coursework deleted successfully',
                })
            )
          }
        });
      },
      onCancel() {
        console.log('Deletion canceled');
      },
    });
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })+ ' ' + date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const formatDueDate = (dateString, dateEnd) => {
    console.log('Received dates:', dateString, dateEnd); // Debugging line
    const date = new Date(dateString);
    const date2 = new Date(dateEnd);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })+ ' ' + date.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit'
    }) + " - " + date2.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })+ ' ' + date2.toLocaleTimeString(undefined, {
      hour: '2-digit',
      minute: '2-digit'
    });
  }

  const columns = [
    { key: 1 , title: 'ID', dataIndex: 'id'},
    { key: 2 , title: 'Assignment Title', dataIndex: 'title', sorter: true},
    { key: 3 , title: 'Format', dataIndex: 'type'},
    { key: 4 , title: 'Subject', dataIndex: 'subject' },
    { key: 5 , title: 'Description', dataIndex: 'description'},
    { key: 6, title: "Practice Mode", dataIndex: "isPracticeMode" },
    {
      key: 7,
      title: 'Due Date',
      dataIndex: 'due_date',
      render: (item) => {
        const {dateStart, dateEnd} = item; // Accessing dateStart and dateEnd directly from the item
        if (dateStart && dateEnd) {
          return formatDueDate(dateStart, dateEnd); // Concatenate and format the dates
        } else {
          return "No dates available";
        }
      },
      sorter: (a, b) => {
        const dateA = new Date(a.dateStart);
        const dateB = new Date(b.dateEnd);
        return dateA - dateB;
      }
    },
    { key: 8 , title: 'State', dataIndex: 'state'},
    { key: 9 , title: 'Action', dataIndex: 'Action'},
  ];

  const handleActionClick = async (id) => {
    let id_student = user.id.toString()
    let id_course = id.toString()
    console.log(id_student,id_course)
    if(!user.roles.includes('ROLE_TEACHER')){
      const {data, error } = await trigger({ student: id_student , course: id_course });
      if(data){
        // console.log("trigger",data)
        navigate(`/courseworks/${data.id}`);

      }
      if (error){
        console.log(error)
      }
    }
  };

  const handleSort = (columnKey) => {
    let direction;
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === 'ascending') {
        direction = null; // Second click, reset to default (unsorted)
      } else if (sortConfig.direction === null) {
        direction = 'descending'; // Third click, sort descending
      } else {
        direction = 'ascending'; // First click, sort ascending
      }
    } else {
      direction = 'ascending'; // New column clicked, sort ascending
    }
    setSortConfig({ key: columnKey, direction });
  };

  const workData = assignmentData?assignmentData['hydra:member']:[];
  const sortedData = [...workData]?.sort((a, b) => {
    if (sortConfig.direction === 'ascending') {
      return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
    }
    if(sortConfig.direction === 'descending'){
      return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
    }
    return 0;
  })
  const TableHeadCell = useCallback((column) => {
    if (column.dataIndex === 'id' || column.dataIndex === 'Action') {
      return column.title;
    }
    if (column.dataIndex === 'state' && !user.roles.includes('ROLE_TEACHER')) {
      return;
    }
    return (
        <>
          <span onClick={() => handleSort(column.dataIndex)} className={"sort_column"}>
            <span>{column.title}</span>
            <span className={"sort__icons"}>
              <UpOutlined className={(sortConfig.direction === "ascending" && sortConfig.key === column.dataIndex) ? "up__icon active" : "up__icon"}/>
              <DownOutlined className={(sortConfig.direction === "descending" && sortConfig.key === column.dataIndex) ? "down__icon active" : "down__icon"}/>
            </span>
          </span>
        </>
    );
  },[sortConfig])

  const TableBodyCell = useCallback((column, item) => {
    switch (column.dataIndex) {
      case 'Action':
        return (
            user.roles.includes('ROLE_TEACHER') ? (
                <div style={{display: "flex", alignItems: "center"}}>
                  <Button onClick={() => editAssisement(item)} className='tableBody__cell-openBtn' icon={<FormOutlined />}>
                  </Button>

                  <Button onClick={() => deleteAssisement(item)} className='tableBody__cell-openBtn' icon={<DeleteOutlined />}>
                  </Button>
                </div>

            ) : (
                <Button onClick={() => handleActionClick(item.id)} className='tableBody__cell-openBtn'>
                  open
                </Button>
            )
        );

      case 'state':
        return (
            user.roles.includes('ROLE_TEACHER') &&
            <span>{item[column.dataIndex]}</span>
        )

      case 'type':
        return <span className='tableBody__cell-formatCol'>{item[column.dataIndex]}</span>;
      case 'due_date':
        const { dateStart, dateEnd } = item;
        if (dateStart && dateEnd) {
          return <span>{formatDueDate(dateStart, dateEnd)}</span>;
        } else {
          return <span>No due date available</span>;
        }
      case "isPracticeMode":
        return (
            <span className='tableBody__cell-formatCol'>{item[column.dataIndex] ? "Yes" : "No"}</span>
        );
      default:
        return item[column.dataIndex];
    }
  },[])

  const importCourseworkFromGoogle = async (e) => {
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/google/${user.id}/oauth`;
  };

  const items = [
    {
      key: '1',
      label: (
          <Button color={"#FDD832"} className={"import__assignment__dropdown"} onClick={importCourseworkFromGoogle}>
            Google Classroom
          </Button>
      ),
    }
    ];


  if(isLoadingAssignments){
    return <CustomSpin/>
  }

  return (
      <AppLayout hideHeader={true} >
        <div className="content__assignments">
          <div className={"assignment__top-page"}>
            <div className="assignments__filter">
              <span> Assignment </span>
            </div>

            <div className="assignments-new">
              <Input
                  type="text"
                  placeholder="search"
                  prefix={<SearchOutlined />}
                  value={searchTerm}
                  onChange={handleSearchChange}
              />
              {user.roles.includes('ROLE_TEACHER') && (
                  <>
                  <Dropdown
                      menu={{items}}
                  >
                    <Button >Import Assignment</Button>
                  </Dropdown>
                    <Button color="#FDD832" onClick={addAssisement}>New Assignment</Button>
                  </>
              )}
            </div>
          </div>
          {user.roles.includes('ROLE_TEACHER') && (
              <>
                <NewAssignment isModalOpen={isModalOpen} onClose={() => {setIsModalOpen(false)}} GradeAndSubjectData={GradeAndSubjectData} />
                <EditAssignment isModalOpen={isEditModalOpen} coursWorkData={coursWorkData} onClose={() => {setIsEditModalOpen(false)}} GradeAndSubjectData={GradeAndSubjectData} />
              </>
          )}
          {

            <Table
                columns={columns}
                data={sortedData}
                TableHeadCell={TableHeadCell}
                TableBodyCell={TableBodyCell}
                noDataText={`You haven't added any assignments yet.`}
                searchTerm={searchTerm}
                page={currentPage}
                onPageChange={handlePageChange}
            />
          }


        </div>
      </AppLayout>
  )
}

export default Remedia
