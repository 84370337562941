import { initialState } from './app.slice';
import { createSelector } from '@reduxjs/toolkit';

const selectDomain = (state) => state.app ?? initialState;


export const selectClassroom = createSelector([selectDomain], (domainState) => {
    return domainState.classroom;
});


export const selectLoading = createSelector([selectDomain], (domainState) => {
    return domainState.loading;
});

export const selectNotification = createSelector([selectDomain], (domainState) => {
    return domainState.notification;

});