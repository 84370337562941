import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  error: null,
  loading: false,
  notification: null,
  classroom: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
   setClassroom: (state, action) => {
      state.classroom = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload.error;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setLoading, setError, setNotification, setClassroom } = appSlice.actions;
export const { name: sliceKey } = appSlice;

export default appSlice.reducer;
