import React from "react";

const MenuSlate = React.forwardRef(({ className, ...props }, ref) =>  (
        <div
            className={`slate-menu ${className}`}
            {...props}
            data-test-id="menu"
            ref={ref}
        />
    )
)

export default MenuSlate;