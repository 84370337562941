import { api } from './api';

export const googleClassroomApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getGoogleClassroomsByUserId: builder.query({
            query: (userId) => ({
                params: {"user_id":userId},
                url: `/google/get-classes`,
                method: 'GET',
            }),
            providesTags: (result, error, { userId }) => [{ type: 'GoogleClassrooms', id: userId }],
        }),

        getCourseworkByClassroomAndUserId: builder.query({
            query: (data) => ({
                params: data,
                url: `/google/get-courses-by-class`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'CourseWorks', id: id }],
        }),

        getRubricsByClassAndCourseWorkId: builder.query({
            query: (data) => ({
                params: data,
                url: `/google/get-Rubrics-by-class-and-coursework`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'CourseWorks', id: id }],
        }),
    }),

});

export const { useLazyGetGoogleClassroomsByUserIdQuery, useLazyGetCourseworkByClassroomAndUserIdQuery, useLazyGetRubricsByClassAndCourseWorkIdQuery } = googleClassroomApi;