import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import remediaLogo from "../../../assets/images/remedia-logo.png";
import { Input, Button, Checkbox, Spin, Alert } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { object, string, ref, boolean } from "yup";
import { Link, useLocation } from "react-router-dom";
import "./signUp.css";
import { useRegisterMutation } from "../../../services/register.api";
import {setLoading} from "../../../redux/app/app.slice";
import {useDispatch} from "react-redux";
import {Spinner} from "@chakra-ui/react";

const SignUp = ({ data, handleFormSubmit, loading }) => {
  const [createUser, {isLoading}] = useRegisterMutation();
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();
  const [initialValues, setInitialValues] = useState(data);
  const dispatch = useDispatch();

  const validationSchema = object().shape({
    firstName: string().required("Required*"),
    lastName: string(),
    email: string().email("Invalid email address").required("Required*"),
    password: string()
        .min(5, "Password must be at least 5 characters")
        .required(""),
    confirmPassword: string()
        .oneOf([ref("password"), null], "Passwords must match")
        .required(""),
    terms: boolean()
        .oneOf([true], "You must accept the terms and conditions")
        .required("You must accept the terms and conditions"),
    classcode: string(),
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get('code');
    if (codeParam) {
      setInitialValues(prevValues => {
        const updatedValues = { ...prevValues, classcode: codeParam };
        console.log('Updated initialValues:', updatedValues);
        return updatedValues;
      });
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(setLoading(isLoading));
  }, [isLoading, dispatch]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const { data, error: userError } = await createUser(values);

    if (data) {
      console.log(data);
      handleFormSubmit(values);
    }
    if (userError) {
      console.log(userError);
      if (userError.data) {
        setErrorMessage(userError.data.message);
        if (userError.data['hydra:description'] && userError.data['hydra:description'].includes('user.UNIQ_IDENTIFIER_EMAIL')) {
          setErrorMessage('An account with this email already exists');
        }
      } else {
        setErrorMessage('An error occurred while creating your account. Please try again.');
      }
      setSubmitting(false);
    }
  };

  return (
      <main className={"register__page"}>
        <img src={remediaLogo} alt="remedia logo" className="__remediaLogo" />
        <div className="container">
          <div className="register __box">
            <h1 className="__title">Sign Up</h1>

            <Formik
                key={JSON.stringify(initialValues)} // Ensure re-render on initialValues change
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
              {({ isSubmitting, isValid, errors }) => (
                  <Form className="register__form">
                    {errorMessage && (
                        <Alert
                            message={errorMessage}
                            type="error"
                            icon={<InfoCircleOutlined />}
                            showIcon
                            className="__errorField"
                        />
                    )}
                    {!isValid && Object.keys(errors).length > 0 && (
                        <Alert
                            message="Please fill out all fields to proceed"
                            type="error"
                            icon={<InfoCircleOutlined />}
                            showIcon
                            className="__errorField"
                        />
                    )}
                    <div className="register__form-fullName">
                      <div className="register__form-firstNameField">
                        <Field name="firstName">
                          {({ field }) => (
                              <Input {...field} placeholder="First Name" size="large" type="text" className="register__form-firstName" />
                          )}
                        </Field>
                      </div>

                      <div className="register__form-lastName">
                        <Field name="lastName">
                          {({ field }) => (
                              <Input {...field} placeholder="Last Name" size="large" type="text" className="register__form-lastName" />
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="register__form-emailField">
                      <Field name="email">
                        {({ field }) => (
                            <Input {...field} placeholder="Email" size="large" type="email" className="register__form-email" />
                        )}
                      </Field>
                    </div>
                    {data.type === "student" && (
                        <div className="register__form-classField">
                          <Field name="classcode">
                            {({ field }) => (
                                <Input {...field} placeholder="Enter your class code" size="large" type="text" className="register__form-class" />
                            )}
                          </Field>
                        </div>
                    )}

                    <div className="register__form-passwordField">
                      <Field name="password">
                        {({ field }) => (
                            <Input.Password {...field} placeholder="Password" type="password" size="large" className="register__form-password" />
                        )}
                      </Field>
                      <ErrorMessage name="password" component="div" className="__errorMessage" />
                    </div>

                    <div className="register__form-confirmPasswordField">
                      <Field name="confirmPassword">
                        {({ field }) => (
                            <Input.Password {...field} placeholder="Confirm password" type="password" size="large" className="register__form-confirmPassword" />
                        )}
                      </Field>
                      <ErrorMessage name="confirmPassword" component="div" className="__errorMessage" />
                    </div>

                    <div className="register__form-checkboxField">
                      <Field name="terms">
                        {({ field }) => (
                            <Checkbox {...field} className="register__form-checkbox">
                              By clicking create an account, I agree that I have read
                              and accepted the <a href="?#">Terms of Use</a> and{" "}
                              <a href="?#">Privacy Policy</a>
                            </Checkbox>
                        )}
                      </Field>
                    </div>
                    <div className={"sign_up__btn__block"}>
                      <Button type="primary" htmlType="submit" block className="register__form-btn __authBtn" disabled={isSubmitting}>
                        {isLoading ? <span>Submit <Spinner height={10} width={10} /></span> : "Submit"}
                      </Button>
                      <span className="register__subTitle __mixSubTitle">
                                        <p>Already have an account? <Link to="/sign-in"> Sign in here </Link></p>
                                    </span>
                    </div>

                  </Form>
              )}
            </Formik>
          </div>
        </div>
      </main>
  );
};

export default SignUp;