import {Modal, Select, Input, message, Image, Switch, DatePicker, Checkbox, Tag, Spin} from "antd";
import { Formik, useFormikContext } from "formik";
import { useEffect, useState, useRef } from "react";
import {
  useLazyGetRubricsByTeacherQuery,
  useLazyGetRubricByIdQuery,
  useLazyGetRubricItemQuery,
} from "../../services/rubrics.api";
import "./EditAssignment.css";
import {
  useUpdateAssignmentMutation,
  useCreateAssignmentMutation,
} from "../../services/assignments.api";
import { AssignmentSchema } from "../../Pages/Assignments/form.schema";
import { useGetMeQuery } from "../../services/auth.api";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { setNotification } from "../../redux/app/app.slice";

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const EditAssignment = ({ isModalOpen, onClose, coursWorkData, GradeAndSubjectData }) => {
  const [triggerRubric, { data: rubricData, isLoading: isLoadingRubrics }] =
    useLazyGetRubricsByTeacherQuery();
  const { data: authenticatedUser, error } = useGetMeQuery();

  const [initialValue, setInitialValue] = useState({
    description: "",
    grade: GradeAndSubjectData?.grade,
    rubric: "",
    state: "active",
    subject: GradeAndSubjectData?.subject,
    title: "",
    type: "Assignment",
    isPracticeMode: false,
    writingAssistance: false,
    allowHandWriting: false,
    allowUploadFile: false,
    dateStart: null,
    dateEnd: null,
  });
  const [updateAssignment, { isLoadingAssismentUpdating }] =
    useUpdateAssignmentMutation(); // Use the mutation hook

  const [selectedRubricTitle, setSelectedRubricTitle] = useState(null);
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const dispatch = useDispatch();
  // Custom hook to reset form when coursWorkData changes
  useEffect(() => {
    setInitialValue({
      title: coursWorkData?.title,
      type: coursWorkData?.type,
      subject: GradeAndSubjectData?.subject,
      grade: GradeAndSubjectData?.grade,
      description: coursWorkData?.description,
      rubric: coursWorkData?.rubric.id,
      state: coursWorkData?.state,
      isPracticeMode: coursWorkData?.isPracticeMode,
      writingAssistance: coursWorkData?.writingAssistance,
      allowHandWriting: coursWorkData?.allowHandWriting,
      allowUploadFile: coursWorkData?.allowUploadFile,
      dateStart: coursWorkData?.dateStart,
      dateEnd: coursWorkData?.dateEnd,
    });
    if (coursWorkData && coursWorkData.image)
      setImage(coursWorkData.image.filePath);
    else setImage(null);

    if (coursWorkData && coursWorkData.rubric) {
      setSelectedRubricTitle(coursWorkData.rubric.id);
    }
  }, [coursWorkData]);

  useEffect(() => {
    triggerRubric({ teacherId: authenticatedUser.id });
  }, []);

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  function handleImageImport(e) {
    if (e.target.files.length) {
      setSelectedImage(e.target.files[0]);
      // id of the div where the file name will be displayed
    }
  }

  return (
    <Modal
      title="Edit Assignment"
      open={isModalOpen}
      onCancel={onClose}
      footer={null}
      className="newAssignment-modal"
    >
      <Formik
        initialValues={initialValue}
        enableReinitialize
        validationSchema={AssignmentSchema}
        validateOnChange={false}
        validateOnBlur={false}
        // onSubmit={async (values, { setSubmitting }) => {
        //     const { data, error } = await createAssignment(values);

        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          const currentDate = new Date().toISOString();
          setFieldValue("rubric", selectedRubricTitle);
          const assignmentData = {
            rubric: { id: selectedRubricTitle },
            description: values.description,
            grade: GradeAndSubjectData?.grade,
            subject: GradeAndSubjectData?.subject,
            title: values.title,
            type: values.type,
            state: values.state,
            updated_at: currentDate,
            writingAssistance: values.writingAssistance,
            allowHandWriting: values.allowHandWriting,
            allowUploadFile: values.allowUploadFile,
            dateStart: values.dateStart,
            dateEnd: values.dateEnd,
            id: coursWorkData.id,
          };
          const { data, error } = await updateAssignment({
            formData: assignmentData,
            id: coursWorkData.id,
            image: selectedImage,
          });

          if (data) {
            setImage(null);
            setSelectedImage(null);
            onClose();
            dispatch(
              setNotification({
                message: "Assignment updated successfully",
                type: "success",
              })
            );
          }

          if (error) {
            dispatch(
              setNotification({
                message: "Error updating assignment",
                type: "error",
              })
            );
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          /* and other goodies */
        }) => (
            <form className={"newAssignment-form"} onSubmit={handleSubmit}>
              {/* Resets the form when coursWorkData changes */}
              <div className="assignmentFields">
                <span className="assignmentFields-title">Assignment Title*</span>
                <Input
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                {errors.title && touched.title && (
                    <div className="error">{errors.title}</div>
                )}
              </div>
              {/*<div className="assignmentFields">*/}
              {/*  <span className="assignmentFields-title">*/}
              {/*    Activate Practice Mode*/}
              {/*  </span>*/}
              {/*  <Checkbox*/}
              {/*    name="isPracticeMode"*/}
              {/*    value={values.isPracticeMode}*/}
              {/*    onChange={(e) =>*/}
              {/*      setFieldValue("isPracticeMode", e.target.checked)*/}
              {/*    }*/}
              {/*  >*/}
              {/*    Practice Mode*/}
              {/*  </Checkbox>*/}
              {/*  {errors.isPracticeMode && touched.isPracticeMode && (*/}
              {/*    <div className="error">{errors.isPracticeMode}</div>*/}
              {/*  )}*/}
              {/*</div>*/}

              <div className="assignmentFields">
                <span className="assignmentFields-title">Format*</span>
                <p className="assignmentFields-desc">
                  Add information about the Assignment format
                </p>
                <Select
                    defaultValue="Assignment"
                    name="type"
                    style={{width: 120}}
                    value={values.type}
                    onChange={(value) => setFieldValue("type", value)}
                    options={[
                      {
                        value: "essay",
                        label: "Essay",
                      },
                      {
                        value: "MCQ",
                        label: "MCQ",
                      },
                      {
                        value: "Q/R",
                        label: "Q/R",
                      },
                    ]}
                />
                {errors.type && touched.type && (
                    <div className="error">{errors.type}</div>
                )}
              </div>
              <div className="assignmentFields">
                <span className="assignmentFields-title">Subject</span>
                <Select defaultValue={GradeAndSubjectData?.subject} disabled/>
              </div>
              <div className="assignmentFields">
                <span className="assignmentFields-title">Grade</span>
                <Select defaultValue={GradeAndSubjectData?.grade} disabled/>
              </div>

              <div className="assignmentFields">
                <span className="assignmentFields-title">State*</span>
                <Select
                    defaultValue="active"
                    style={{width: 120}}
                    name="state"
                    value={values.state}
                    onChange={(value) => setFieldValue("state", value)}
                    options={[
                      {
                        value: "active",
                        label: "active",
                      },
                      {
                        value: "draft",
                        label: "draft",
                      },
                    ]}
                />
                {errors.state && touched.state && (
                    <div className="error">{errors.state}</div>
                )}
              </div>

              <div className="assignmentFields practice__mode">
                <span className="assignmentFields-title__practice_mode mb-2">
                  Practice Options
                </span>
                <div className={"features__field"}>
                  <label>Practice Mode</label>
                  <Switch
                      value={values.isPracticeMode}
                      handleBg={"#000095"}
                      onChange={(value) =>
                          setFieldValue("isPracticeMode", value)
                      }
                  />
                </div>
              </div>

              <div className="assignmentFields">
                <span className="assignmentFields-title">Rubric</span>
                {/*<Button className='assignmentFields-rubricBtn' onClick={openRubrics}>*/}
                {/*    <EditOutlined/>*/}
                {/*    Edite the Rubric*/}
                {/*</Button>*/}
                {rubricData && (
                    <Select
                        className={"select-rubric edit__select_rubric"}
                        style={{marginTop: 12}}
                        placeholder="Select a rubric"
                        name="rubric"
                        value={selectedRubricTitle}
                        // onChange={value => setFieldValue('rubric', value)}
                        onChange={(value) => {
                          setFieldValue("rubric", value);
                          setSelectedRubricTitle(value);
                        }}
                    >
                      {rubricData["hydra:member"].map((rubric) => (
                          <Select.Option key={rubric.id} value={rubric.id}>
                            {rubric.title}
                          </Select.Option>
                      ))}
                    </Select>
                )}
                {errors.rubric && touched.rubric && (
                    <div className="error">{errors.rubric}</div>
                )}
              </div>

              <div className={"assignmentFields"}>
                <span className="assignmentFields-title field__feacher">
                  Assignment Features
                </span>

                <div className={"features__field"}>
                  <label>Provide writing assistance features.</label>
                  <Switch
                      value={values.writingAssistance}
                      handleBg={"#000095"}
                      onChange={(value) =>
                          setFieldValue("writingAssistance", value)
                      }
                  />
                </div>
                <div className={"features__field"}>
                  <label>Allow students to upload handwritten documents.</label>
                  <Switch
                      value={values.allowHandWriting}
                      onChange={(value) => setFieldValue("allowHandWriting", value)}
                  />
                </div>
                <div className={"features__field"}>
                  <label>Allow students to upload PDF or Word documents.</label>
                  <Switch
                      value={values.allowUploadFile}
                      onChange={(value) => setFieldValue("allowUploadFile", value)}
                  />
                </div>
              </div>

              <div className={"assignmentFields"}>
                <span className="assignmentFields-title">Due date</span>
                <RangePicker
                    showTime={{format: "HH:mm"}}
                    format="YYYY-MM-DD HH:mm"
                    value={
                      values.dateStart && values.dateEnd
                          ? [dayjs(values.dateStart), dayjs(values.dateEnd)]
                          : null
                    }
                    onChange={(dates) => {
                      console.log("dates ==>::", dates);
                      setFieldValue(
                          "dateStart",
                          dayjs(dates[0], "YYYY-MM-DD HH:mm")
                      );
                      setFieldValue("dateEnd", dayjs(dates[1], "YYYY-MM-DD HH:mm"));
                    }}
                />
              </div>

              <div className="assignmentFields">
                <span className="assignmentFields-title">Instructions</span>
                <p className="assignmentFields-desc">
                  Provide instructions or reference texts for the students.
                  <br/> This text will be visible to them as they compose their
                  responses.
                </p>
                <TextArea
                    rows={5}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                />
                {errors.description && touched.description && (
                    <div className="error">{errors.description}</div>
                )}
                <div className="assignmentFields">
                  <div style={{textAlign: "right"}}>
                    <div className={"block__img"}>
                      <div id={"selectedfile"}>
                        {" "}
                        {image && selectedImage == null ? (
                            <img
                                src={`${process.env.REACT_APP_API_BASE_URL}/uploads/images/${image}`}
                            />
                        ) : selectedImage ? (
                            <>{selectedImage.name}</>
                        ) : (
                            <>No file selected</>
                        )}
                      </div>
                      <button
                          onClick={handleButtonClick}
                          type={"button"}
                          className="assignmentFields-addBtn"
                      >
                        Add Image
                      </button>
                    </div>
                    <br/>
                    <input
                        type="file"
                        ref={fileInputRef}
                        aria-label="Add Image"
                        style={{display: "none"}}
                        onChange={handleImageImport}
                    />
                  </div>
                </div>
              </div>

              <div style={{textAlign: "center"}}>
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="createAssignment-btn"
                >
                  Save
                  {isLoadingAssismentUpdating && (
                      <Spin size="small"/>
                  )}
                </button>
              </div>
            </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditAssignment;
