import React, {useEffect, useState, useRef} from 'react'
import RubricScoresAccordion from "../../Components/accordion/rubricScores-accordion/RubricScoresAccordion";
import AssignmentAccordion from "../../Components/accordion/assignment-accordion/AssignmentAccordion";
import { InfoCircleOutlined, PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import AnalyticsProgress from "../../Components/analyticsProgress/AnalyticsProgress";
import exportfileIcon from "../../assets/images/exportFile-icon.svg";
import remediaIcon from "../../assets/images/remedia-icon.svg";
import saveIcon from "../../assets/images/save-icon.svg";
import outIcon from "../../assets/images/Out-icon.svg";
import "./submissionDetails.css";
import {Button} from 'antd';
import AppLayout from "../Layout/AppLayout";
import StatisticsModal from "../../Components/StatisticsModal/StatisticsModal";
import Tooltip from "antd/lib/tooltip";
import {useSubmitOpenIAFeedbackMutation, useEditStudentSubmissionContentMutation} from "../../services/studentsubmissions.api";
import {useParams} from "react-router-dom";
import html2pdf from "html2pdf.js";
import {Spinner} from "@chakra-ui/react";
import Swal from "sweetalert2";
import {useCreateStudentSubmissionAssismentMutation} from "../../services/studentsubmitionassisment.api";
import {
    useLazyGetStudentCourseWorkQuery,
    useLazyGetStudentSubmissionPdfQuery
} from "../../services/studentcourseworks.api";
import {useDispatch, useSelector} from "react-redux";
import {setNotification} from "../../redux/app/app.slice";
import {selectClassroom} from "../../redux/app/app.selectors";




const SubmissionDetails =  () => {
    const {id} = useParams();
    const [trigger,{data: studentCourseWork, error, isLoading:isLoading}] = useLazyGetStudentCourseWorkQuery();
    const [triggerPDF] = useLazyGetStudentSubmissionPdfQuery();
    const [submitOpenIAFeedback] = useSubmitOpenIAFeedbackMutation();
    const [isGenerating, setIsGenerating] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const dispatch = useDispatch()
    const [isDownloading, setIsDownloading] = useState(false);

    const [visible, setVisible] = useState(false);
    const [reponse, setReponse] = useState(false);
    const [reponseParse, setReponseParse] = useState(false);
    const [rubric, setRubric] = useState(false);
    const [feedbacks, setFeedbacks] = useState([]);
    const [feedbacksArray, setFeedbacksArray] = useState(null);
    const [newContent, setNewContent] = useState([
        {
            type: 'paragraph',
            children: [{ text: '' }],
        },
    ])
    const [accuracySpelling, setAccuracySpelling] = useState(0);
    const [lexicalDensity, setLexicalDensity] = useState(0);
    const [syntaxComplexity, setSyntaxComplexity] = useState(0);
    const [redabilityScore, setRedabilityScore] = useState(0);
    const textareaRef = useRef([]);

    const [editStudentSub] = useEditStudentSubmissionContentMutation()
    const [editStudentSubContent] = useCreateStudentSubmissionAssismentMutation()

    const [highestPoint,setHighestPoint] = useState(0)
    const classroom = useSelector(selectClassroom);

    const handleCorrection = async () =>{
        setIsGenerating(true);
        const  { data  , error } =   await submitOpenIAFeedback(
            {answer:studentCourseWork.studentSubmission.content,
                assignement:studentCourseWork.coursework.id
                ,grade:studentCourseWork.coursework.grade
                ,rubric_id: studentCourseWork.coursework.rubric.id
                ,submission_id:studentCourseWork.studentSubmission.id}
        )

        if(data){
            setReponse(data)
            setReponseParse(data)
            dispatch(setNotification({message: "Data generated successfully", type: "success"}))
        }
        if(error){
            console.log("error  IAAAA",error)
            dispatch(setNotification({message: "An unexpected error occurred. Please try again.", type: "error"}))
        }
        setIsGenerating(false);
    }
    console.log("studentCourseWork;::",studentCourseWork)
    const fetchData =  () => {


         if(studentCourseWork && studentCourseWork.studentSubmission.studentSubmissionsAssesments.length>0){
            setReponse(studentCourseWork.studentSubmission.studentSubmissionsAssesments[studentCourseWork.studentSubmission.studentSubmissionsAssesments.length -1].corrected_text)
             setReponseParse(studentCourseWork.studentSubmission.studentSubmissionsAssesments[studentCourseWork.studentSubmission.studentSubmissionsAssesments.length -1].corrected_text)
             setNewContent(studentCourseWork.studentSubmission.studentSubmissionsAssesments[studentCourseWork.studentSubmission.studentSubmissionsAssesments.length -1].corrected_text["studentSubmissionAfterCorrection"])
         }else{
             setReponse(JSON.parse(studentCourseWork.studentSubmission.preAssesment.llmOutputs[studentCourseWork.studentSubmission.preAssesment.llmOutputs.length -1].output))
             setReponseParse(JSON.parse(studentCourseWork.studentSubmission.preAssesment.llmOutputs[studentCourseWork.studentSubmission.preAssesment.llmOutputs.length -1].output))
             setNewContent(studentCourseWork.studentSubmission.content)
         }


    }

    useEffect(() => {
       if (studentCourseWork)
       {
           fetchData()
           setHighestPoint(studentCourseWork.coursework.rubric.highestPoint)
       }
    }, [studentCourseWork]);

    useEffect( () => {
        console.log("reponse",reponse)
        setFeedbacksArray(reponse.feedbacks)
        setFeedbacks(reponse.actionable_feedbacks)
    }, [reponse]);


    useEffect( () => {
        trigger(id);
    }, []);

    useEffect(() => {
        if(studentCourseWork) {
            setRubric(studentCourseWork.coursework.rubric.title)
        }
    }, [studentCourseWork]);


    const handleExport = (studentCourseWork) => {
        const element = document.getElementById('left');
        const elementRight = document.getElementById('right');
        element.style.display = 'block';
        let title = '';
        let filename= 'CourseWork';
        if(studentCourseWork ){
            let courseWork = studentCourseWork.coursework;
            title= courseWork.title;
        }
        if(title){
            filename= filename+'_'+ `${title}.pdf`;
        }
        const opt = {
            margin: [10, 10, 10, 10], // Marges : haut, gauche, bas, droite
            filename: filename, // Nom du fichier PDF généré
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: {
                scale: 2, // Augmenter la résolution
                logging: true, // Activer le logging pour le débogage
                letterRendering: true, // Améliorer le rendu des lettres
                useCORS: true, // Pour charger les images externes
            },
            jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }, // Format A4
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }, // Gestion des sauts de page
        };

        html2pdf()
            .from(element)
            .set(opt)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages();
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i);
                    pdf.setFontSize(10);
                    pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() - 50, pdf.internal.pageSize.getHeight() - 10);
                }
            })
            .save()
        // meme chose pour le right
        html2pdf()
            .from(elementRight)
            .set(opt)
            .toPdf()
            .get('pdf')
            .then((pdf) => {
                    const totalPages = pdf.internal.getNumberOfPages();
                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(10);
                        pdf.text(`Page ${i} of ${totalPages}`, pdf.internal.pageSize.getWidth() - 50, pdf.internal.pageSize.getHeight() - 10);
                    }
                }
            )
            .save()
    };

    const addFeedback = () => {
        setFeedbacks([...feedbacks, 'Add your feedback here ...']);
    };

    const removeFeedback = (index) => {
        const newFeedbacks = feedbacks.filter((_, i) => i !== index);
        setFeedbacks(newFeedbacks);
    };

        const handleChange = (index, event) => {
            const newFeedbacks = [...feedbacks];
            newFeedbacks[index] = {actionable_feedback
                : event.target.value};
            setFeedbacks(newFeedbacks);
        };
        const handleOpenModal = () => {
            setVisible(true); // Afficher la modal lors du clic sur le bouton
        };

    const handleCloseModal = () => {
        setVisible(false); // Fermer la modal
    };

    const [criteriaScore , setCriteriaScore] = useState([])
    const criteriaUsage = (criteriaIndex, score, content) => {
        console.log("criteriaUsage",criteriaIndex, score, content)

        const updatedCriteriaScore = feedbacksArray.map((criterion, cIndex) => {
            if (cIndex === criteriaIndex) {
                return {
                    ...criterion,
                    score: score,
                    scoreExplanation: content
                };
            }
            return criterion;
        });
        setFeedbacksArray(updatedCriteriaScore);
    };


    const handleSubmit = async (event) =>{
        event.preventDefault()
        event.stopPropagation();

        if (studentCourseWork.studentSubmission){
            setIsSubmitting(true);
            try {
                // merge 2 arrays updatedCriteriaScore and updatedFeedBacks
                const corrected={feedbacks: feedbacks, scores: feedbacksArray}
                // parse corrected array to json
                const mergedArray = JSON.stringify(corrected)
                const  { data:dataEdit, error } = await editStudentSub({id:studentCourseWork.studentSubmission.id,state:"Graded"})
                console.log("criteriaScore final",feedbacksArray)
                const  {data: dataStudentSubmission, error: errorStudentSubmission} = await editStudentSubContent(
                    {
                        corrected_text: {feedbacks: feedbacksArray, actionable_feedbacks: feedbacks,'studentSubmissionAfterCorrection':newContent},
                        studentSubmission: `/api/student-submissions/${studentCourseWork.studentSubmission.id}`,
                        created_at: new Date().toISOString(),
                        type: "overrided correction",editStudentSub
                    })
                if (dataEdit || dataStudentSubmission){
                    // alert("Data updated successfully")
                    dispatch(setNotification({message: "Data updated successfully", type: "success"}))
                }
                if (error || errorStudentSubmission){
                    //alert("Error, We encountered an issue while trying to update the assesment . Please check your input and try again. If the problem persists, contact support for assistance.")
                    dispatch(setNotification({message: "An unexpected error occurred. Please try again.", type: "error"}))
                }
            }catch (error) {
                console.error("Error during submission:", error);
                alert("An unexpected error occurred. Please try again.");
            } finally {
                setIsSubmitting(false); // Set loading state back to false when the process finishes
            }
        }
    }


    useEffect(() => {
        if (studentCourseWork && studentCourseWork.studentSubmission && studentCourseWork.studentSubmission.preAssesment && studentCourseWork.studentSubmission.preAssesment.statstics){
            const firstObject = studentCourseWork && studentCourseWork.studentSubmission?.preAssesment?.statstics?.error_composition[0];
            const sumOfErrors = Object.values(firstObject).reduce((sum, value) => sum + value, 0);
            setAccuracySpelling(sumOfErrors);
            setLexicalDensity((studentCourseWork.studentSubmission.preAssesment.statstics?.lexical_error?.results['sentence info']?.type_token_ratio)*100);
            setSyntaxComplexity(studentCourseWork.studentSubmission.preAssesment.statstics?.lexical_error?.results['sentence info']?.words_per_sentence);
            setRedabilityScore(studentCourseWork.studentSubmission.preAssesment.statstics?.lexical_error?.results['readability grades']['Coleman-Liau']);
        }
    }, [studentCourseWork]);

    const handleDownloadPDF = async ()=>{
        setIsDownloading(true);
        const {data, error} = await triggerPDF({student_coursework_id: studentCourseWork?.id, classroom_id: classroom?.id})

        if (data){
            const blob = new Blob([data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', `student_submission.pdf`); // Set the download file name

            // Append to the body and click to trigger download
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        if (error){
            dispatch(setNotification({type:"error", message:"Error, We encountered an issue while trying to download pdf ,contact support for assistance"}))
        }
        setIsDownloading(false);
        return data;
    }
    useEffect(() => {
        feedbacks && feedbacks.map((_, index) => {
            if (textareaRef.current[index]) {
                textareaRef.current[index].style.height = 'auto'; // Reset height
                textareaRef.current[index].style.height = `${textareaRef.current[index].scrollHeight}px`; // Set height based on scrollHeight
            }
        });
    }, [feedbacks]);
    return (
        <AppLayout hideHeader={true} >
            <div className="teacher__container">
                <h1 className="teacher__container-title">
                    <span>Assignment</span>
                    <Tooltip title="This is your coursework" placement={"top"}>
                        <InfoCircleOutlined style={{fontSize: "10px"}}/>
                    </Tooltip>
                </h1>

                <div className="teacher__assignment" id={"teacher__assignment"}>
                    <div className="right">
                        <AssignmentAccordion data={studentCourseWork} updatedCriteriaScore={criteriaScore}
                                             updatedFeedBacks={feedbacks} setNewContent={(value)=>setNewContent(value)}/>
                    </div>
                    <div className="left">
                        <div id={'left'} className={"content__left__sidebar"}>
                            <div className="critical__issues_container">
                                <div className="critical-issues">
                                    <h1 className="critical-issues__title">
                                        Linguistic statistics
                                    </h1>
                                    <Button className="critical-issues__button"
                                            onClick={handleOpenModal}
                                    >
                                        <img src={outIcon} alt="out icon"/>
                                        <span>View</span>
                                    </Button>
                                </div>
                                <div className="analytics">
                                    <AnalyticsProgress
                                        label="Spelling Accuracy"
                                        percentage={100 - accuracySpelling}
                                        progressColor="#FF3838"
                                    />
                                    <AnalyticsProgress
                                        label="Lexical diversity"
                                        percentage={Math.round(lexicalDensity * 10) / 10}
                                        progressColor="#5A88FF"
                                    />
                                    <AnalyticsProgress
                                        label="Syntactic complexity"
                                        percentage={Math.round(syntaxComplexity * 10) / 10}
                                        progressColor="#44DA85"
                                    />
                                </div>
                            </div>
                            <div className="rubric__score-container">
                                <div className="rubric__score-title">
                                    <h1>{rubric ? rubric : "Rubric Scores"}</h1>
                                </div>
                                <div className="rubric__usage">
                                    {
                                        feedbacksArray ? feedbacksArray.map((item, index) =>
                                                item ? (
                                                    <RubricScoresAccordion
                                                        key={index}
                                                        index={index}
                                                        criteriaIndex={index}
                                                        rubricHightScore={ highestPoint}
                                                        criteriaTitle={item.criterion}
                                                        scores={item.score}
                                                        criteriaUsage={(criteriaIndex, score, content) => criteriaUsage(criteriaIndex, score, content)}
                                                        scoreExplanation={item.scoreExplanation}
                                                        onClick={(index)=> console.log("index : ",index)}
                                                    />
                                                ) : null
                                            )
                                            : <Spinner/>}
                                </div>
                            </div>
                            <div className="feedback-container">
                                <div className="feedback__title">
                                    <h1>Actionable feedback</h1>
                                    <Button className="addFeedback__button" onClick={addFeedback}>
                                        <PlusCircleOutlined/>
                                        <span>Add</span>
                                    </Button>
                                </div>
                                {feedbacks ? feedbacks.map((feedback, index) => (
                                    <div key={index} className="feedback__content">
                                        <span>{index + 1}.</span>
                                        <textarea
                                            ref={(el) => (textareaRef.current[index] = el)}
                                            className="feedback__content-text"
                                            value={feedback.actionable_feedback}
                                            onChange={(event) => handleChange(index, event)}
                                        />
                                        <CloseCircleOutlined className="close" onClick={() => removeFeedback(index)}/>
                                    </div>
                                )) : <Spinner/>}
                            </div>
                        </div>
                        <div className={"fixed__submition__btn"}>
                            <div className={"block__btnGroup__save"}>
                                <Button onClick={handleCorrection} type="button" className="corrections-btn">
                                    {isGenerating ? <Spinner height={10} width={10}/> :
                                        <>
                                            <img src={remediaIcon} alt="remedia icon"/>
                                        </>
                                    }

                                    <span>Generate</span>
                                </Button>
                                <Button type="button"  onClick={(event)=>handleSubmit(event)}
                                        className={"corrections-btn"}>
                                    {isSubmitting ? <Spinner height={10} width={10}/> :
                                        <>
                                            <img src={saveIcon} alt="save icon"/>
                                        </>
                                    }
                                    Save
                                </Button>
                            </div>

                            <Button className="exportFile-btn" onClick={() => handleDownloadPDF(studentCourseWork)}>
                                {isDownloading ? <Spinner height={10} width={10} /> :<img src={exportfileIcon} alt="remedia icon"/>}
                                <span>Export File</span>
                            </Button>
                        </div>
                    </div>

                </div>
            </div>
            <StatisticsModal visible={visible} handleCloseModal={handleCloseModal}
                             dataStatistic={studentCourseWork?studentCourseWork.studentSubmission?.preAssesment?.statstics:[]}/>
        </AppLayout>
    )

}

export default SubmissionDetails