import StudentAssignmentAccordion from "../../Components/accordion/student-assignment-accordion/StudentAssignmentAccordion";
import { InfoCircleOutlined, AlertOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import AppLayout from "../Layout/AppLayout";
import "./StudentSubmissionDetails.css";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import {useLazyGetStudentCourseWorkQuery} from "../../services/studentcourseworks.api";
import {useLazyGetRubricByIdQuery} from "../../services/rubrics.api";
import ViewRubricModal from "../../Components/viewRubricModal/ViewRubricModal";
import {setNotification} from "../../redux/app/app.slice";
import {Tag} from "antd";




const StudentSubmissionDetails = () => {
    const [getStudentCourseWork, {data:studentCourseworkData, isLoading}] =  useLazyGetStudentCourseWorkQuery()
    const { assignmentId } = useParams();

    useEffect(() => {
        getStudentCourseWork(assignmentId);
    }, [assignmentId]);


    if(isLoading){
        return <CustomSpin/>
    }
    return (
      <AppLayout hideHeader={true}>
         <div className="teacher__container">
             <div className={"student__sub__header"}>
                 <h1 className="teacher__container-title">
                     <span>Student Assignment</span>
                     <InfoCircleOutlined style={{fontSize: "18px"}} className={"icon-tooltip"}/>
                     { studentCourseworkData?.coursework?.isPracticeMode && <Tag color={"#000095"} className="practice-mode"><AlertOutlined /> Practice Mode</Tag>}
                 </h1>

             </div>

             <div className="teacher__assignment" style={{display: "block"}}>
                 <div className="right">
                     <StudentAssignmentAccordion assignment={studentCourseworkData}
                                                 refreshData={() => getStudentCourseWork(assignmentId)}/>
                 </div>
            </div>
         </div>
      </AppLayout>




  )
}

export default StudentSubmissionDetails
