import Table from "../../Components/table/Table";
import {DownOutlined, FormOutlined, UpOutlined, DeleteOutlined,ImportOutlined} from "@ant-design/icons"
import { Link } from "react-router-dom";
import {Button, message, Spin,Tabs,Radio} from "antd";
import "./rubric.css"
import AppLayout from "../Layout/AppLayout";
import {useLazyGetRubricsQuery, useLazyGetRubricByIdQuery,useDeleteRubricMutation,useLazyGetRubricsByTeacherQuery,useLazyGetRubricsByTitleQuery,useCreateRubricMutation} from "../../services/rubrics.api";
import {useLazyGetRubricsStandardQuery,useLazyGetRubricStandardByIdQuery} from "../../services/rubricsStandard.api";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ViewRubricModal from "../../Components/viewRubricModal/ViewRubricModal";
import EditRubricModal from "../../Components/rubricActions/editRubricModal/EditRubricModal.jsx";
import Swal from "sweetalert2";
import {useGetMeQuery} from "../../services/auth.api";
import {setNotification} from "../../redux/app/app.slice";
import {useDispatch} from "react-redux";


const columns = [
    { key: 1 , title: 'ID', dataIndex: 'id'},
    { key: 2 , title: 'Rubric Title', dataIndex: 'title'},
    { key: 3 , title: 'Format', dataIndex: 'format'},
    { key: 4 , title: 'Edit', dataIndex: 'edit'},
    { key: 5 , title: '', dataIndex: 'Action'},
    { key: 6 , title: 'Delete', dataIndex: 'deleted'},
];

const columnsStandard = [
    { key: 1 , title: 'ID', dataIndex: 'id'},
    { key: 2 , title: 'Rubric Title', dataIndex: 'title'},
    { key: 3 , title: 'Type', dataIndex: 'type'},
    { key: 4 , title: '', dataIndex: 'Action'},
    { key: 5 , title: 'Import', dataIndex: 'Import'},
];


const Rubric = () => {

    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [rubricItem, setRubricItem] = useState(null);
    const navigate = useNavigate();
    const [rubricItemData, setRubricItemData] = useState(null);
    const [rubricItemDataEdit, setRubricItemDataEdit] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'id', direction: null });
    const dispatch = useDispatch();

    const [deleteRubric] = useDeleteRubricMutation();
    // const [trigger, { data: rubrics, isLoading: isLoadingRubric}] = useLazyGetRubricsQuery();
    const [trigger, { data: rubrics, isLoading: isLoadingRubric}] = useLazyGetRubricsByTeacherQuery();
    const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
    const [getRubricItem, { data: rubricByItem, isLoading: isLoadingRubricItem }] = useLazyGetRubricByIdQuery();
    const [mode, setMode] = useState('custom');
    const [triggerRubricByTitle, { data: rubricByTitle, isLoading: isLoadingRubricByTitle}] = useLazyGetRubricsByTitleQuery();
    const [triggerRubricsStandard, { data: rubricsStandard, isLoading: isLoadingRubricStandard,errorStandard}] = useLazyGetRubricsStandardQuery();
    const [getRubricStandardItem, { data: rubricStandardByItem, isLoading: isLoadingRubricStandardItem }] = useLazyGetRubricStandardByIdQuery();
    const [createRubric, {isLoading: isLoadingNewRubric}] = useCreateRubricMutation()
    let teacher=null

    useEffect(() => {
        if(authenticatedUser){
            trigger({teacherId: authenticatedUser.id});
            console.log("ruuuu",rubrics)
        }
    }, [authenticatedUser]);
    console.log('rubrics =>', rubrics);


    const handleModeChange = (e) => {
        setMode(e.target.value);
    };

    useEffect(() => {
        triggerRubricsStandard();
    }, [rubricsStandard]);

    const initialValues = {
        name: '',
        grade: '',
        scoreTotal: 0,
        criteria: [
            {
                title: '',
                scores: [{ level: '', points: '', description: '' }]
            },
        ],
    };

    const handleActionClick = async (item) => {
        setRubricItem(item.id);
        console.log(item);
        if(item['@type'] === "RubricStandard"){
            const {data, error} = await getRubricStandardItem(item.id);
            if (data) {
                setRubricItemData(data);
                setIsViewModalOpen(!isViewModalOpen);
            }
        }else {
            const {data, error} = await getRubricItem(item.id);
            if (data) {
                setRubricItemData(data);
                setIsViewModalOpen(!isViewModalOpen);
            }
        }
    };

    const handleEditRubric = async (id) => {
        console.log("edit rubric", id);
        const { data, error } = await getRubricItem(id);
        console.log("edit rubric data", data);
        if (data) {
            setRubricItemDataEdit(data);
            setIsEditModalOpen(!isEditModalOpen);
        }

    }

    const handleDeleteRubric = async  (rubric) => {
        if(rubric.courseworks && rubric.courseworks.length > 0){
            console.log('Cannot delete rubric with courseworks');
            return Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Cannot delete Rubric with coursework!',
                confirmButtonText: 'OK',
                confirmButtonColor: '#000095',
                timer: 3000 // Optional: Auto close after 3 seconds
            });
        }else {
                Swal.fire({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "Do you want to delete this Rubric?",
                    showDenyButton: false,
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    confirmButtonColor: "#000095",
                }).then((result) => {
                    if (result.isConfirmed) {
                        deleteRubric({ rubricId: rubric.id }).then((result) => {
                            if (result.data) {
                                console.log('Deleted data ::', result.data);
                                // triggerAssignment();
                                dispatch(
                                    setNotification({
                                        type: "success",
                                        message: "Rubric deleted successfully",
                                    })
                                );
                            }
                        });
                    }
                });
        }

    }

    const handleImportClick = async (item) => {

            if(authenticatedUser){
                teacher= "/api/teachers/"+authenticatedUser.id
            }
            const rubricData = {
                title: item.title,
                type: item.type,
                format: item.format,
                criterias: item.criterias,
                teacher:teacher,
                grade: item.grade,
                created_at: new Date().toISOString(),
                updated_at: new Date().toISOString(),
            };
            const {data, error} = await createRubric(rubricData);
            if(data){
                dispatch(
                    setNotification({
                        type: "success",
                        message: "Rubric imported successfully",
                    })
                )
                // redirect to rubric list
                navigate('/rubric');
            }
            if(error){
                console.log("error", error)
                dispatch(
                    setNotification({
                        type: "error",
                        message: "Error importing rubric",
                    })
                )
            }


    };
    const handleSort = (columnKey) => {
        let direction;
        if (sortConfig.key === columnKey) {
            if (sortConfig.direction === 'ascending') {
                direction = null; // Second click, reset to default (unsorted)
            } else if (sortConfig.direction === null) {
                direction = 'descending'; // Third click, sort descending
            } else {
                direction = 'ascending'; // First click, sort ascending
            }
        } else {
            direction = 'ascending'; // New column clicked, sort ascending
        }
        setSortConfig({ key: columnKey, direction });
    };

    const rubData = rubrics?.['hydra:member'] || [];
    const sortedData = [...rubData]?.sort((a, b) => {
        if (sortConfig.direction === 'ascending') {
            console.log("a ::", a);
            return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
        }
        if(sortConfig.direction === 'descending'){
            return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
        }
        return 0;
    })

    const rubDataStandard = rubricsStandard?.['hydra:member'] || [];
    const sortedDataStandard = [...rubDataStandard]?.sort((a, b) => {
        if (sortConfig.direction === 'ascending') {
            console.log("a ::", a);
            return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
        }
        if(sortConfig.direction === 'descending'){
            return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
        }
        return 0;
    })


    const TableHeadCell = (column) => {
        if (column.dataIndex === 'id' || column.dataIndex === 'Action' || column.dataIndex === 'Edit' || column.dataIndex === 'deleted' || column.dataIndex === 'Import') {
            return column.title;
        }
        else {
            return (
                <>
                    <span onClick={() => handleSort(column.dataIndex)} className={"sort_column"}>
                      <span>{column.title}</span>
                      <span className={"sort__icons"}>
                        <UpOutlined className={(sortConfig.direction === "ascending" && sortConfig.key === column.dataIndex) ? "up__icon active" : "up__icon"}/>
                        <DownOutlined className={(sortConfig.direction === "descending" && sortConfig.key === column.dataIndex) ? "down__icon active" : "down__icon"}/>
                      </span>
                    </span>
                </>
            );
        }
    };

    const TableBodyCell = (column, item) => {
        switch (column.dataIndex) {
            case 'Action':
                return (
                    <Button onClick={() => handleActionClick(item)} className='tableBody__cell-openBtn'>
                        view
                    </Button>
                );
            case 'Import':
                return (
                    <Button onClick={() => handleImportClick(item)} className='tableBody__cell-openBtn'>
                        <ImportOutlined/>
                    </Button>
                );
            case 'Format':
                return <span className='tableBody__cell-formatCol'>{item[column.dataIndex]}</span>;

            case "edit":
                return <button className="tableBody__cell-edit" onClick={() => handleEditRubric(item.id)}>
                    <FormOutlined style={{fontSize : "18px", color:"#000095"}}/>
                </button>

            case 'deleted':
                return <button className="tableBody__cell-delete" onClick={() => handleDeleteRubric(item)}>
                    <DeleteOutlined style={{fontSize : "18px", color:"#a33333"}}/>
                </button>

            default:
                return item[column.dataIndex];
        }
    };

    const handleNewRubric = () => {
        navigate('/rubric/new');
        console.log("new rubric")
    }

    return (
        <AppLayout hideHeader={true}>
            <div className="rubricContent">
                <div className="rubricContent__header">
                    <div className="rubricContent__header-title">
                        <span>Rubrics</span>
                    </div>

                    <div className="rubricContent__header-new">
                        <Link to="/rubric/new">
                            <Button type="primary" onClick={handleNewRubric}> New Rubric </Button>
                        </Link>
                    </div>
                </div>

                {/* Radio Group for Table Selection */}
                <div className={"rubric__tabs"}>
                    <Radio.Group onChange={handleModeChange} value={mode}>
                        <Radio.Button value="custom">My Rubrics</Radio.Button>
                        <Radio.Button value="standard">Rubric Library</Radio.Button>
                    </Radio.Group>
                </div>
                {/* Conditionally Rendered Tables */}
                {mode === 'standard' ? (
                    <Table
                        columns={columnsStandard}
                        data={sortedDataStandard}
                        TableHeadCell={TableHeadCell}
                        TableBodyCell={TableBodyCell}
                        useFilter={false}
                        useSearchBar
                        noDataText={"No Standard Rubrics found"}
                        usePagination={true}
                        showEntriesPerPageSelect={true}
                        isLoading={isLoadingRubric}
                    />
                ) : (
                    <Table
                        columns={columns}
                        data={sortedData}
                        TableHeadCell={TableHeadCell}
                        TableBodyCell={TableBodyCell}
                        useFilter={false}
                        useSearchBar
                        noDataText={"No Custom Rubrics found"}
                        usePagination={true}
                        showEntriesPerPageSelect={true}
                        isLoading={isLoadingRubric}
                    />
                )}

                {/* View Modal */}
                <ViewRubricModal
                    isViewModalOpen={isViewModalOpen}
                    setIsViewModalOpen={setIsViewModalOpen}
                    rubricItem={rubricItem}
                    rubricItemData={rubricItemData}
                    importRubric={handleImportClick}
                />

                {/* Edit Modal */}
                {rubricItemDataEdit && (
                    <EditRubricModal
                        isEditModalOpen={isEditModalOpen}
                        setIsEditModalOpen={setIsEditModalOpen}
                        rubricItemDataEdit={rubricItemDataEdit}
                    />
                )}
            </div>
        </AppLayout>
    );
}

export default Rubric
