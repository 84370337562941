import React from "react";

const ButtonSlate = React.forwardRef(
    ({className, active, reversed, ...props }, ref) => {
        const computedClassName = `
    ${className || ''} 
    cursor-pointer 
    ${reversed && active ? 'color-white' : ''} 
    ${reversed && !active ? 'color-light-gray' : ''} 
    ${!reversed && active ? 'color-black' : ''} 
    ${!reversed && !active ? 'color-gray' : ''}
  `.trim();
        return (
            <div
                className={computedClassName}
                {...props}
                ref={ref}
            />
        )
    }
)

export default ButtonSlate;