

const ListPuce = () => {
    return (
        <svg width="20" viewBox="0 0 20 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 5.5C1.2 5.5 0.5 6.2 0.5 7C0.5 7.8 1.2 8.5 2 8.5C2.8 8.5 3.5 7.8 3.5 7C3.5 6.2 2.8 5.5 2 5.5ZM2 0.5C1.2 0.5 0.5 1.2 0.5 2C0.5 2.8 1.2 3.5 2 3.5C2.8 3.5 3.5 2.8 3.5 2C3.5 1.2 2.8 0.5 2 0.5ZM2 10.5C1.2 10.5 0.5 11.2 0.5 12C0.5 12.8 1.2 13.5 2 13.5C2.8 13.5 3.5 12.8 3.5 12C3.5 11.2 2.8 10.5 2 10.5ZM5.5 1V3H19.5V1H5.5ZM5.5 13H19.5V11H5.5V13ZM5.5 8H19.5V6H5.5V8Z"
                fill="currentColor"/>
        </svg>
    )
}

export default ListPuce;