import { MoreOutlined, LinkOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import copyCodeIcon from "../../assets/images/copyCodeIcon.svg";
import classLesson from "../../assets/images/class-lesson.svg"
import classVector from "../../assets/images/classVector.svg"
import {Button, Dropdown, message} from "antd"
import "./classCard.css"
import { Link } from "react-router-dom";
import {useLazyGetClassroomByIdQuery, useLazyGetClassroomCountQuery} from "../../services/classroom.api";
import {useEffect} from "react";
import {useSelector} from "react-redux";

const ClassCard = ({
                       onClick,
                       id,
                       key,
                       className,
                       subject,
                       courswork,
                       submissionsPregraded,
                       submissionsGraded,
                       students,
                       code,
                       handleEdit,
                       handleDelete,

                   }) => {

    const [triggerClassroomItem, { data: classroomItem, isLoading: isLoadingClassroomItem }] = useLazyGetClassroomByIdQuery(id);
    const [triggerClassRoomCountGraded,{ data: countGraded, isLoading: isLoadingClassroomItemCountGraded }] = useLazyGetClassroomCountQuery()
    const [triggerClassRoomCountPreGraded,{ data: countPreGraded, isLoading: isLoadingClassroomItemCountPreGraded }] = useLazyGetClassroomCountQuery()

    const handleCopyCode = () => {
        navigator.clipboard.writeText(code);
        message.success(`Code ${code} copied to clipboard `);
    }
    useEffect(() => {
        triggerClassRoomCountGraded({id:id,state:submissionsGraded})
        triggerClassRoomCountPreGraded({id:id,state:submissionsPregraded})

    }, []);
    const handleCopyLink = () => {
        const path =  window.location.origin + "/sign-up?code=" + code;
        navigator.clipboard.writeText(path);
        message.success("Link copied to clipboard");
    }

    const addStudentsItems = [
        {
            label: <button className="classDropDownItems" onClick={handleCopyLink}>
                <LinkOutlined style={{fontSize:"20px",color:"#FDD832"}}/> Copy link
            </button>,
            key: '0',
        },
        {
            label: <button className="classDropDownItems" onClick={handleCopyCode}>
                <img src={copyCodeIcon} alt="copy code icon" /> Copy Code
            </button>,
            key: '1',
        },
    ];

    const handleEditClass = async () => {
        const { data } = await triggerClassroomItem(id);
        if (data) {
            handleEdit(data);
        }
    }

    const handleDeleteClass = async () => {
        const { data } = await triggerClassroomItem(id);
        if (data) {
            handleDelete(data);
        }
    }

    const actionItems = [
        {
            label: <button className="classDropDownItems" onClick={handleEditClass}>
                <EditOutlined style={{fontSize:"20px",color:"#FDD832"}}/> Edit
            </button>,
            key: '0',
        },
        {
            label: <button className="classDropDownItems" onClick={handleDeleteClass}>
                <DeleteOutlined style={{fontSize:"20px",color:"#FDD832"}}/> Delete
            </button>,
            key: '1',
        },
    ];



    return (
        <div className="classContainer">
            <div className="classContainer__content" onClick={onClick}>
                <h1>{className}</h1>
                <div>
                    <span className="classContainer__key">Subject : </span>
                    <span className="classContainer__value">{subject}</span>
                </div>
                <div>
                    <span className="classContainer__key">Assignment : </span>
                    <span className="classContainer__value">{courswork}</span>
                </div>
                <div>
                    <span className="classContainer__key">Submissions pregraded : </span>
                    <span className="classContainer__value">{countPreGraded && countPreGraded["hydra:member"][0][1]}</span>
                </div>
                <div>
                    <span className="classContainer__key">Submissions graded : </span>
                    <span className="classContainer__value">{countGraded && countGraded["hydra:member"][0][1]}</span>
                </div>
                <div>
                    <span className="classContainer__key">{students>1 ? "Students" : "Student" } :</span>
                    <span className="classContainer__value"> {students}</span>
                </div>
            </div>
            <div className={"block__btn"}>
                <Dropdown
                    menu={{items: addStudentsItems}}
                    trigger={['click']}
                    placement="bottomLeft"

                >
                    <Button
                        className="AddStudentBtn"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the link click
                        }}
                    >
                        Add student
                    </Button>
                </Dropdown>

                <Dropdown
                    menu={{ items: actionItems }}
                    trigger={['click']}
                    placement="bottomLeft"
                >
                    <button
                        className="action"
                        onClick={(e) => {
                            e.stopPropagation();  // Prevent the link click
                        }}
                    >
                        <MoreOutlined style={{ fontSize: "28px", color: "#FDD832" }} />
                    </button>
                </Dropdown>
            </div>
            <div className={"classContainer__images"} onClick={onClick}>
            <img src={classVector} alt="class vector" className="classVectorBg" />
            <img src={classLesson} alt="class vector" className="classLessonIcon" />
            </div>
        </div>
    );
};


export default ClassCard;
