import {DoubleRightOutlined} from "@ant-design/icons";
import "./assignmentAccordion.css";
import React, {useEffect, useState} from "react";
import Tooltip from "antd/lib/tooltip";
import CustomSpin from "../../customSpin/CustomSpin";
import TextEditor from "../../SlateEditor/TextEditor";
import {useCorrectStatisticsMutation} from "../../../services/statistics.api";
import {setNotification} from "../../../redux/app/app.slice";
import {useLazyGetRubricByIdQuery} from "../../../services/rubrics.api";
import { useDispatch } from "react-redux";
import ViewRubricModal from "../../viewRubricModal/ViewRubricModal";


const AssignmentAccordion = ({data,setNewContent}) => {

    const initialAccordionStates = {
        1: true,
        2: true,
    };
    const [accordionStates, setAccordionStates] = useState(initialAccordionStates);
    const [getRubricItem, { data: rubricByItem, isLoading: isLoadingRubricItem }] = useLazyGetRubricByIdQuery();
    const [rubricItemData, setRubricItemData] = useState(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [correctStatistics, {isLoading: isLoadingStatistics}] = useCorrectStatisticsMutation()
    const dispatch = useDispatch();
    const handleContentChange = (value) => {
        setNewContent(value)
    };
    const [rubricId, setRubricId] = useState(null);

  useEffect(() =>  {

    const fetchData = async () => {
      if(data && data.formated) {
        const text = data.formated
        correctStatistics({ answer: text })
            .then(response => {
              // Destructure the response object to access dataStatistics and error
              const { dataStatistics, error } = response;

              // Check if there was an error
              if (error) {
                console.error('Error:', error);
                return; // Exit the function if there's an error
              }

              // Handle the successful response
             const statistics = response.data.response
                console.log('statistics',statistics)
            })
            .catch(err => {
              // Handle any errors that occur during the promise
              console.error('Error in promise:', err);
            });
      }
    };
    fetchData();

  }, [data]);

    useEffect(() => {
        setRubricId(data && data?.coursework?.rubric?.id)
    }, [data]);

  const toggle = (index) => {
    setAccordionStates((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

    const rubricShowHandle = async () => {
        const { data, error } = await getRubricItem(rubricId);
        if (data) {
            setRubricItemData(data);
            setIsViewModalOpen(!isViewModalOpen);
        }
        if (error) {
            console.log(error);
            dispatch(
                setNotification({
                    message: "Error fetching rubric",
                    type: "error",
                })
            );
        }
    }

  if(!data)
      return <CustomSpin/>;

  return (
      <>

              <div id={"right"}>
              <div className={`accordion`}>
                <div className="accordion__title" onClick={() => toggle(1)}>
                  <Tooltip title={data.coursework.description} placement={"top"}>
                    <h1>{data.coursework.title}</h1>
                  </Tooltip>

                  <DoubleRightOutlined className={`arrow ${accordionStates[1] ? "toggled" : ""}`}/>
                </div>
                <div className={`accordion__content ${accordionStates[1] ? "toggled" : ""}`} style={{display: 'flex'}}>
                  {data && data.coursework.image &&
                      <div className={"figure-coursework"}>
                         <img
                          src={`${process.env.REACT_APP_API_BASE_URL}/uploads/images/${data.coursework.image.filePath}`}
                          alt={data.coursework.title}/>
                      </div>
                  }
                    <div className={"instruction__content"}>
                        <h1>{data.coursework.title}</h1>
                        <p>
                            {data.coursework.description}
                        </p>
                        <span><button className={"view-btn"} onClick={rubricShowHandle}>View Rubric</button></span>
                    </div>
                </div>
              </div>

                  <div className={`accordion`}>
                  <div className="accordion__title-student" onClick={() => toggle(2)}>
                  <h1>Student Answer {data && <span style={{
                    color: 'white',
                    fontWeight: 500
                  }}> <i>By : {data.student.first_name + " " + data.student.family_name}</i></span>}</h1>


                  <DoubleRightOutlined className={`arrow ${accordionStates[2] ? "toggled" : ""}`}/>
                </div>
                <div className={`accordion__content-student  ${accordionStates[2] ? "toggled" : ""}`}>
                  <TextEditor studentCourseWork={data} onContentChange={handleContentChange}/>

                </div>

              </div>
            </div>

          <ViewRubricModal
              isViewModalOpen={isViewModalOpen}
              setIsViewModalOpen={setIsViewModalOpen}
              //rubricItem={rubricItem}
              rubricItemData={rubricItemData}
          />

      </>
  );
};

export default AssignmentAccordion;