import { MoreOutlined, LinkOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import copyCodeIcon from "../../assets/images/copyCodeIcon.svg";
import classLesson from "../../assets/images/class-lesson.svg"
import classVector from "../../assets/images/Vector-primary.svg"
import { Button, Dropdown } from "antd"
import "./style.css"
import { Link } from "react-router-dom";


const addStudentsItems = [
    {
        label: <button className="classDropDownItems">
            <LinkOutlined style={{fontSize:"20px",color:"#FDD832"}}/> Copy link
        </button>,
        key: '0',
    },
    {
        label: <button className="classDropDownItems">
            <img src={copyCodeIcon} alt="copy code icon" /> Copy Code
        </button>,
        key: '1',
    },
];

const actionItems = [
    {
        label: <button className="classDropDownItems">
            <EditOutlined style={{fontSize:"20px",color:"#FDD832"}}/> Edit
        </button>,
        key: '0',
    },
    {
        label: <button className="classDropDownItems">
            <DeleteOutlined style={{fontSize:"20px",color:"#FDD832"}}/> Delete
        </button>,
        key: '1',
    },
];

const StudentClassCard = ({
                       className,
                        onClick,
                       subject,
                        courswork,
                       students,
                              teacher
                   }) => {
    return (
        <div className="classStudentContainer"  onClick={onClick}>
            <div className="classContainer__content">
                <h1>{className}</h1>
                <div>
                    <span className="classContainer__key">Subject : </span>
                    <span className="classContainer__value">{subject}</span>
                </div>
                <div>
                    <span className="classContainer__key">Assignment : </span>
                    <span className="classContainer__value">{courswork}</span>
                </div>
                <div>
                    <span className="classContainer__key">{students > 1 ? "Students" : "Student"} : </span>
                    <span className="classContainer__value">{students}</span>
                </div>
                <div>
                    <span className="classContainer__key">Teacher : </span>
                    <span className="classContainer__value">{teacher}</span>
                </div>
            </div>
                <img src={classVector} alt="class vector" className="classVectorBg"/>
                <img src={classLesson} alt="class vector" className="classLessonIcon"/>

        </div>
    );
};


export default StudentClassCard;
