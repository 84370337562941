import React, { useMemo,useState, useCallback,useEffect,useContext } from 'react'
import {Layout, Spin} from 'antd';
import {
    Slate,
    Editable,
    withReact,
    useSlateStatic,
    useReadOnly,
    ReactEditor
} from 'slate-react'
import {
    Node,
    createEditor,
} from 'slate'
import { withHistory } from 'slate-history'
import CardError from './CardError'
import BlockElement from './BlockElement'
import BlockInlineCorrection from "./BlockInlineCorrection";
import { DataContext } from '../../contexts/DataContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectCorrection, selectLoading } from '../../redux/app/app.selectors'
import { setCorrection, setStateError } from '../../redux/app/app.slice'
import ButtonSlate from "../_util/buttonSlate";
import JustifyIcon from "../icons/justifyIcon";
import ToolbarSlate from "../_util/toolbarSlate";
import IconSlate from "../_util/iconSlate";
import isHotkey from 'is-hotkey'
import {Editor, Element as SlateElement, Transforms} from "slate";
import {useSlate} from "slate-react";
import BoldIcon from "../icons/boldIcon";
import Italic from "../icons/italic";
import UnderLine from "../icons/underLine";
import HeadingOne from "../icons/headingOne";
import HeadingTwo from "../icons/headingTwo";
import BlockQuote from "../icons/blockQuote";
import ListNum from "../icons/listNum";
import ListPuce from "../icons/listPuce";
import TextAlignLeft from "../icons/textAlignLeft";
import TextAlignCenter from "../icons/textAlignCenter";
import TextAlignRight from "../icons/textAlignRight";

const resetEditor = (editor, nodes) => {
    const { selection } = editor;


    // Remove all nodes currently in the editor
    editor.removeNodes({ at: { anchor: editor.start([]), focus: editor.end([]) }});

    // Insert new nodes if `nodes` parameter is provided
    if(nodes) editor.insertNodes(Node.isNode(nodes) ? [nodes] : nodes);

    // Restore the previous selection or set it to the end of the editor
  
};


// Fonction pour insérer un élément dans un tableau à une position spécifiée
function insert(array, element, position) {
    if (position < 0 || position > array.length) {
        console.error("Invalid position.");
        return array;
    }

    array.splice(position, 0, element);
    return array;
}

// Fonction pour remplacer un élément dans un tableau à une position spécifiée
function replace(array, element, position) {
    if (position < 0 || position >= array.length) {
        console.error("Invalid position.");
        return array;
    }

    console.log(array[position])

    array[position] = element;
    return array;
}



const apiOutput = `S My name is Susan .\nA -1 -1|||noop|||-NONE-|||REQUIRED|||-NONE-|||1\n\nS I'm forteen and I life in Germany .\nA 1 2|||UNK|||' m|||REQUIRED|||-NONE-|||0\nA 5 6|||R:NOUN->VERB|||live|||REQUIRED|||-NONE-|||0\n\nS My hobbys are go to discos, sometimes I hear music in the radio .\nA 1 2|||R:SPELL|||hobbies|||REQUIRED|||-NONE-|||0\nA 3 4|||R:VERB:FORM|||going|||REQUIRED|||-NONE-|||0\nA 9 10|||R:VERB:MW|||listen to|||REQUIRED|||-NONE-|||0\nA 11 12|||R:PREP:WC|||on|||REQUIRED|||-NONE-|||0\n\nS In the summer I go bathing in a lake .\nA -1 -1|||noop|||-NONE-|||REQUIRED|||-NONE-|||1\n\nS I haven't any brothers or sisters .\nA 1 1|||M:None->AUX|||do|||REQUIRED|||-NONE-|||0\nA 1 3|||R:WO|||n't have|||REQUIRED|||-NONE-|||0\n\nS We take busses to scool .\nA 2 3|||R:NOUN:NUM|||buses|||REQUIRED|||-NONE-|||0\nA 4 5|||R:SPELL|||school|||REQUIRED|||-NONE-|||0\n\nS I visit year 9 at my school .\nA 1 2|||R:VERB:TENSE|||visited|||REQUIRED|||-NONE-|||0\n\nS My birthday is on Friday .\nA -1 -1|||noop|||-NONE-|||REQUIRED|||-NONE-|||1\n\nS I hope I will become a new guitar .\nA 4 5|||R:VERB:WC|||get|||REQUIRED|||-NONE-|||0\n\nS I'm looking forward to get a e-mail from you .\nA 1 2|||UNK|||' m|||REQUIRED|||-NONE-|||0\nA 5 6|||R:VERB:FORM|||getting|||REQUIRED|||-NONE-|||0\nA 6 7|||R:DET:WC|||an|||REQUIRED|||-NONE-|||0`;

const initialValue = [
   
    {
        type: 'paragraph',
        children: [
          { text: ' ' },
        
        ]
      },

]




// Extrait et met en évidence les erreurs dans le texte brut




// Fonction principale du composant TextEditor
const TextEditor = ({studentCourseWork,onContentChange}) => {

    const HOTKEYS = {
        'mod+b': 'bold',
        'mod+i': 'italic',
        'mod+u': 'underline',
        'mod+`': 'code',
    }
    const LIST_TYPES = ['numbered-list', 'bulleted-list']
    const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']

    const renderElem = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])


    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(
            editor,
            format,
            TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
        )
        const isList = LIST_TYPES.includes(format)
        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                LIST_TYPES.includes(n.type) &&
                !TEXT_ALIGN_TYPES.includes(format),
            split: true,
        })
        let newProperties
        if (TEXT_ALIGN_TYPES.includes(format)) {
            newProperties = {
                align: isActive ? undefined : format,
            }
        } else {
            newProperties = {
                type: isActive ? 'paragraph' : isList ? 'list-item' : format,
            }
        }
        Transforms.setNodes(editor, newProperties)
        if (!isActive && isList) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        }
    }
    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)
        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }
    const isBlockActive = (editor, format, blockType = 'type') => {
        const { selection } = editor
        if (!selection) return false
        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n =>
                    !Editor.isEditor(n) &&
                    SlateElement.isElement(n) &&
                    n[blockType] === format,
            })
        )
        return !!match
    }
    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }
    const Element = ({ attributes, children, element }) => {
        const style = { textAlign: element.align }
        switch (element.type) {
            case 'block-quote':
                return (
                    <blockquote style={style} {...attributes}>
                        {children}
                    </blockquote>
                )
            case 'bulleted-list':
                return (
                    <ul style={style} {...attributes}>
                        {children}
                    </ul>
                )
            case 'heading-one':
                return (
                    <h1 style={style} {...attributes}>
                        {children}
                    </h1>
                )
            case 'heading-two':
                return (
                    <h2 style={style} {...attributes}>
                        {children}
                    </h2>
                )
            case 'list-item':
                return (
                    <li style={style} {...attributes}>
                        {children}
                    </li>
                )
            case 'numbered-list':
                return (
                    <ol style={style} {...attributes}>
                        {children}
                    </ol>
                )
            case 'color':
                return (
                    <span style={{ color: element.color }}>{children}</span>
                )
            default:
                return (
                    <p style={style} {...attributes}>
                        {children}
                    </p>
                )
        }
    }
    const Leaf = ({ attributes, children, leaf }) => {
        if (leaf.bold) {
            children = <strong>{children}</strong>
        }
        if (leaf.italic) {
            children = <em>{children}</em>
        }
        if (leaf.underline) {
            children = <u>{children}</u>
        }
        return <span {...attributes}>{children}</span>
    }
    const BlockButton = ({ format, icon }) => {
        const editor = useSlate()
        return (
            <ButtonSlate
                active={
                    isBlockActive(
                        editor,
                        format,
                        TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
                    )}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                <IconSlate icon={icon} />
            </ButtonSlate>
        )
    }
    const MarkButton = ({ format, icon }) => {
        const editor = useSlate()
        return (
            <ButtonSlate
                active={isMarkActive(editor, format)}
                onMouseDown={event => {
                    event.preventDefault()
                    toggleMark(editor, format)
                }}
            >
                <IconSlate icon={icon} />
            </ButtonSlate>
        )
    }

    const dataRef = []



    const handleChangeContent = (value) => {
        const isAstChange = editor.operations.some(
            op => 'set_selection' !== op.type
        )
        if (isAstChange) {
            onContentChange(value);
        }
    };

    const filteredErrors = dataRef.filter(item => item.children && item.children[0] && typeof item.children[0].error === 'string' && !item.children[0].error.startsWith('-1'));
    console.log("selectorss filteredErrors---------------------",filteredErrors)
    const renderElement = useCallback(({ attributes, children, element }) => {
        console.log('element.type',element.type,'element',element)
        switch (element.type) {
            case 'error':
                return (
                    <CheckListItemElement attributes={attributes} children={children} element={element} phrase={element.children[0].text} error={element.children[0].error} />       )
            case 'paragraph':
                return <p {...attributes}>{children}</p>
                       
            default:
                return <p {...attributes}>{children}</p>
        }
    }, [])
    const [editor] = useState(() => withReact(createEditor()))


    return (

        <Slate
            editor={editor}
            initialValue={
                studentCourseWork.studentSubmission.studentSubmissionsAssesments.length>0?studentCourseWork.studentSubmission.studentSubmissionsAssesments[studentCourseWork.studentSubmission.studentSubmissionsAssesments.length -1].corrected_text["studentSubmissionAfterCorrection"]:studentCourseWork.studentSubmission.content
            }
            onChange={handleChangeContent}
        >

            <ToolbarSlate>
                <MarkButton format="bold" icon={<BoldIcon />} />
                <MarkButton format="italic" icon={<Italic />} />
                <MarkButton format="underline" icon={<UnderLine />} />
                <BlockButton format="heading-one" icon={<HeadingOne />} />
                <BlockButton format="heading-two" icon={<HeadingTwo />} />
                <BlockButton format="block-quote" icon={<BlockQuote />} />
                <BlockButton format="numbered-list" icon={<ListNum />} />
                <BlockButton format="bulleted-list" icon={<ListPuce />} />
                <BlockButton format="left" icon={<TextAlignLeft />} />
                <BlockButton format="center" icon={<TextAlignCenter />} />
                <BlockButton format="right" icon={<TextAlignRight />} />
                <BlockButton format="justify" icon={<JustifyIcon />} />
            </ToolbarSlate>

            <Editable  className={"slate"}   style={{
                minHeight: '400px',
                padding:"10px"

            }}
                       onKeyDown={event => {
                           for (const hotkey in HOTKEYS) {
                               if (isHotkey(hotkey, event)) {
                                   event.preventDefault()
                                   const mark = HOTKEYS[hotkey]
                                   toggleMark(editor, mark)
                               }
                           }
                       }}
                       renderLeaf={renderLeaf}
                       renderElement={renderElem}
            />

        </Slate>

)
}

// Composant pour afficher chaque élément de texte avec des erreurs éventuelles
const CheckListItemElement = (props) => {
    const editor = useSlateStatic()
    const readOnly = useReadOnly()
    const dispatch = useDispatch()

    const [errorType, SetErrorType] = useState([])
    const [final,setFinal] = useState([])
    const [phrase , setPhrase] = useState([])
    const [stateEr , setStateEr] = useState([])
    let er = []

    const getError = ()=>{

        const phrase = props.phrase;
        setPhrase(phrase)
        console.log(phrase,errorType,final)

        props.error.forEach(element => {
            let obj = new Object();
            let splitedError= element.split("|||");
            obj.location = splitedError[0]
            obj.type = splitedError[1]
            obj.element = splitedError[2]
            obj.need = splitedError[3]
            SetErrorType([...errorType,obj])
        });
    }

    const renderComponent = () =>{
        
        errorType.forEach((element,index) => {
            let location = element.location.split(" ");
            let phrases = props.phrase.split(" ");
            console.log(location[0] != location[1])
            

            let phraseFinal = location[0] != location[1] ? replace(phrases,<BlockElement  identifier={index} initial={phrases[location[0]]} element={element.element} type={element.type} need={element.need} phrases={phrases} location={location[0]} />,location[0]) : location[0] != '-1' ? insert(phrases,<BlockElement  identifier={index} initial={phrases[location[0]]} element={element.element} type={element.type} need={element.need} location={location[0]} phrases={phrases} />,location[0]):phrases;

        
            setFinal(phraseFinal.map((error,index)=>{

                if(!React.isValidElement(error,index))

                // <span    contentEditable={false} key={index} onClick={()=>{console.log('hani')}}>{error} {" "}</span>
                return   <BlockInlineCorrection  initial={error} phrases={phrases} location={index} />
                else
                    return error

            }))

          

            // Check if the conditions are met
    if (location[0] != location[1] || location[0] != '-1') {
        // Create an object with the desired properties
        let obj = {
            identifier: index,
            initial: phrases[location[0]],
            element: element.element,
            type: element.type,
            need: element.need,
            phrases: phrases,
            location: location[0]
        };
        
      

            
        }
        })

    
        
    }

    useEffect(() => {

        getError();
        renderComponent()



    },[phrase])




    return (
        <div
            {...props.attributes}

        >
            {final.map((word) => {
                    return word
                }

            )}

        </div>
    )
}


export default TextEditor