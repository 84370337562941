import {boolean, object, string} from 'yup';

export const AssignmentSchema = object({
  title: string().required('Title is required'),
  type: string().required('Type is required'),
  description: string().required('Instruction is required'),
//  rubric: string().required('Rubric is required'),
  state: string().required('Select a state value'),

});
