import React, {useEffect, useState} from "react";
import ClassCard from "../../Components/classCrad/ClassCard";
import { SearchOutlined } from "@ant-design/icons";
import {Button, Input, Select, Modal, message} from "antd"
import "./classes.css"
import AppLayout from "../Layout/AppLayout";
import { Formik, Field, Form } from 'formik';
import {useGetMeQuery} from "../../services/auth.api";
import {
    useCreateClassRoomMutation,
    useEditClassroomMutation,
    useDeleteClassroomMutation,
     useLazyGetClassroomCountQuery
} from "../../services/classroom.api";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import ClassModal from "../../Components/classModal/ClassModal";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setClassroom, setNotification} from "../../redux/app/app.slice";
import StudentClassCard from "../../Components/studentClassCard/StudentClassCard";
import {useLazyGetClassroomsByTeacherQuery,useLazyGetClassroomsByStudentQuery} from "../../services/classroom.api";
import {selectClassroom} from "../../redux/app/app.selectors";


const Classes = () => {
    const classroom = useSelector(selectClassroom);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data: authenticatedUser, error } = useGetMeQuery();
    const [createClassRoom, {isLoading: isLoadingCreateClass}] = useCreateClassRoomMutation()
    const [codeGenerated, setCodeGenerated] = useState('');
    // const [triggerClassrooms, { data: dataClassroom, error: errorClassroom, isLoading: isLoadingClassroom }] = useLazyGetClassroomsQuery();
    const [classroomData, setClassroomData] = useState([]);
    const [mode, setMode] = React.useState('create');
    const [updateClassRoom, {isLoading: isLoadingUpdateClass}] = useEditClassroomMutation();
    const [deleteClassRoom, {isLoading: isLoadingDeleteClass}] = useDeleteClassroomMutation();
    const [countSubmissions,setCountSubmission] = useState([])
    const [initialValues, setInitialValues] = React.useState({
        name: '',
        subject: '',
        grade: '',
    });
    const [editingClassId, setEditingClassId] = useState(null); // To track the class being edited
    const [triggerClassrooms, {data:dataClassroom, isLoading:isLoadingClassroom }] = useLazyGetClassroomsByTeacherQuery();
    const [triggerClassroomsByStudent, {data:dataClassroomStudent, isLoading:isLoadingClassroomStudent }] = useLazyGetClassroomsByStudentQuery();
    let isTeacher = false;
    if(authenticatedUser){
        const roles = authenticatedUser.roles
        isTeacher = roles.includes('ROLE_TEACHER');
    }
    const resetForm = () => {
        setInitialValues({
            name: '',
            subject: '',
            grade: '',
        });
        setEditingClassId(null);
        setMode('create');
    };
    const handleActionClick = (classroom) => {
        dispatch(setClassroom(classroom));
        navigate(`/coursework`);
    }

    const showModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleCancel=()=>{
        setIsModalOpen(!isModalOpen)
    }

    useEffect(() => {
        if (!isModalOpen) resetForm();
    }, [isModalOpen]);




    useEffect(() => {
        if(authenticatedUser && authenticatedUser.id) {
            console.log('isTeacher', isTeacher);
            if(isTeacher) {
                triggerClassrooms({teacherId: authenticatedUser.id});
                console.log('classRoomData', dataClassroom);
                console.log('teacherId', authenticatedUser.id);
            }
            else{
                triggerClassroomsByStudent({students: authenticatedUser.id});
                console.log('classRoomData', dataClassroomStudent);
                console.log('studentId', authenticatedUser.id);
            }
        }
    }, [classroom,authenticatedUser,triggerClassroomsByStudent,triggerClassrooms]);

    const generateRandomCode = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 10; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters[randomIndex];
        }
        setCodeGenerated(result);
    };

    useEffect(() => {
        generateRandomCode();
    }, []);
    console.log("current user", authenticatedUser);
    const handleSubmit = async (values) => {
        const classroomData = {
            ...values,
            teacher: authenticatedUser,
            code: codeGenerated,
            created_at: new Date().toISOString(),
        }

        try {
            if (mode === 'create') {
                const { data } = await createClassRoom({...classroomData, code: codeGenerated});
                if (data) {
                    dispatch(
                        setNotification(
                            {
                                type: 'success',
                                message: 'Classroom created successfully',
                            }
                        )
                    )
                    setIsModalOpen(false);
                    // triggerClassrooms();
                    setInitialValues({
                        name: '',
                        subject: '',
                        grade: '',
                    });
                    setEditingClassId(null);
                    setMode('create');
                }
            } else if (mode === 'edit' && editingClassId) {
                const { data } = await updateClassRoom({ id: editingClassId,name: classroomData.name, subject: classroomData.subject, grade: classroomData.grade });
                if (data) {
                    dispatch(
                        setNotification({
                            type: 'success',
                            message: 'Classroom updated successfully',
                        })
                    )
                    setIsModalOpen(false);
                    // triggerClassrooms();
                    setInitialValues({
                        name: '',
                        subject: '',
                        grade: '',
                    });
                    setEditingClassId(null);
                    setMode('create');
                }
            }
        } catch (error) {
            message.error('Error submitting classroom');
            console.error("Error:", error);
            setIsModalOpen(false);
        }


    }

    const handleEdit = (classData) => {
        setInitialValues(classData)
        setEditingClassId(classData.id);
        setMode('edit');
        setIsModalOpen(true);
        if(classroom.id===classData.id){
            dispatch(setClassroom(classData));
        }
    };

    const handleCreate = () => {
        setInitialValues({
            name: '',
            subject: '',
            grade: '',
        });
        setMode('create');
        setIsModalOpen(true);
    };

    const handleDelete = (id) => {
        console.log('id', id)
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "Do you want to delete this class?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#000095",
        }).then((result) => {
            if (result.isConfirmed) {
                deleteClassRoom(id).then((result) => {
                    if (result.data) {
                        console.log('Deleted data ::', result.data);
                        dispatch(
                            setNotification({
                                type: 'success',
                                message: 'Classroom deleted successfully',
                            })
                        );
                    }
                });
            }
        });
    }



    if (isLoadingClassroom){
        return <CustomSpin />
    }
    return (
        <AppLayout hideHeader={true} hideClass={true}>
            <div className="mainClasses">
                <div className="classesContent__header">
                    <div className="classesContent__header-title">
                        <h1>Classes</h1>
                    </div>

                    <div className="classesContent__header-new">
                        <Input
                            type="text"
                            placeholder="search"
                            prefix={<SearchOutlined/>}
                            className="search-bar"
                            // value={searchTerm}
                            // onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        {
                            isTeacher && (
                                <Button onClick={showModal}>
                                    Create Class
                                </Button>
                            )
                        }
                        <ClassModal
                            isModalOpen={isModalOpen}
                            handleCancel={() => setIsModalOpen(false)}
                            handleSubmit={handleSubmit}
                            mode={mode}
                            initialValues={initialValues}
                        />
                    </div>
                </div>
                <div className="classesContent__Bottom">
                    {(Array.isArray(authenticatedUser?.roles)
                        ? authenticatedUser?.roles.includes("ROLE_TEACHER")
                        : authenticatedUser?.roles === "ROLE_TEACHER") && (
                        dataClassroom?.['hydra:member'].map((classroom) => (
                            <ClassCard
                                onClick={() => handleActionClick(classroom)}
                                key={classroom.id}
                                id={classroom.id}
                                className={classroom.name}
                                subject={classroom.subject}
                                submissionsPregraded= {"Pre-graded"}
                                submissionsGraded={"Graded"}
                                courswork={classroom.courswork.length}
                                students={
                                    classroom.students.length < 10 && classroom.students.length !== 0
                                        ? `0${classroom.students.length}`
                                        : classroom.students.length
                                }
                                code={classroom.code}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                            />
                        ))
                    )}

                    {(Array.isArray(authenticatedUser?.roles)
                        ? authenticatedUser.roles.includes("ROLE_USER") && !authenticatedUser.roles.includes("ROLE_TEACHER")
                        : authenticatedUser.roles === "ROLE_USER" && authenticatedUser.roles !== "ROLE_TEACHER") && (
                        dataClassroomStudent?.['hydra:member'].map((classroom) => (
                            <StudentClassCard
                                onClick={() => handleActionClick(classroom)}
                                key={classroom.id}
                                id={classroom.id}
                                className={classroom.name}
                                subject={classroom.subject}
                                courswork={
                                    classroom.courswork.length < 10 && classroom.courswork.length !== 0
                                    ? `0${classroom.courswork.length}`
                                    : classroom.courswork.length

                            }
                                students={
                                    classroom.students.length < 10 && classroom.students.length !== 0
                                        ? `0${classroom.students.length}`
                                        : classroom.students.length
                                }
                                code={classroom.code}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                teacher={classroom?.teacher?.first_name + " " + classroom?.teacher?.family_name}
                            />
                        ))
                    )}


                </div>
            </div>
        </AppLayout>
    )
}

export default Classes
