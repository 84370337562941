import React from "react";

const UnderLine = () => {

    return (
        <svg height="18" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 15V17H0V15H14ZM11 8.215C10.9671 8.87498 10.7711 9.51653 10.4297 10.0823C10.0883 10.6481 9.61207 11.1205 9.04352 11.4572C8.47497 11.794 7.83184 11.9847 7.17161 12.0122C6.51138 12.0397 5.85461 11.9033 5.26 11.615C4.57464 11.3185 3.99341 10.8241 3.59077 10.1952C3.18813 9.56633 2.98242 8.83154 3 8.085V0.00500011H1V8.215C1.03383 9.15641 1.28885 10.0766 1.74442 10.9012C2.19998 11.7257 2.84329 12.4314 3.62227 12.9611C4.40125 13.4908 5.294 13.8296 6.22827 13.9502C7.16254 14.0707 8.11207 13.9696 9 13.655C10.1811 13.2613 11.2059 12.5012 11.9252 11.485C12.6446 10.4689 13.0211 9.24981 13 8.005V0.00500011H11V8.215ZM11 0H13H11ZM3 0H1H3Z"
                fill="currentColor"/>
        </svg>
    )
}

export default UnderLine;