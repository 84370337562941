// src/components/NotFound.js

import React from 'react';
import { Link } from 'react-router-dom';
import remediaLogo from "../../assets/images/remedia-logo.png";  // Assuming React Router is used for navigation

const NotFound = () => {
    return (
        <div style={styles.container}>
            <img src={remediaLogo} alt="remedia logo" width={300}
                 style={styles.logo} />
            <h1 style={styles.header}>404 - Page Not Found</h1>
            <p style={styles.text}>Sorry, the page you are looking for does not exist.</p>
            <Link to="/" style={styles.homeLink}>Go Home</Link>
        </div>
    );
};

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '20vh',
        fontFamily: 'Arial, sans-serif',
    },
    header: {
        fontSize: '48px',
        color: '#000095',
    },
    text: {
        fontSize: '18px',
        color: '#333',
    },
    homeLink: {
        marginTop: '20px',
        display: 'inline-block',
        textDecoration: 'none',
        backgroundColor: '#000095',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
    },
    logo: {
        width: '300px',
    }
};

export default NotFound;
