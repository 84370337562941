import { api } from './api';

export const StudentSubmissions = api.injectEndpoints({
    endpoints: (builder) => ({
        getStudentSubmissions: builder.query({
            query: () => ({
                url: '/api/student-submissions',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'StudentSubmissions', id: 'LIST' },
                    ],
        }),
        getStudentSubmission: builder.query({
            query: (id) => ({
                url: `/api/student-submissions/${id}`,
                method: 'GET',
            }),
            providesTags: (result, error, { id }) => [{ type: 'StudentSubmissions', id: id }],
        }),
        submitOpenIAFeedback: builder.mutation({
            query: (openIAData) => ({
                url: '/api/student-submission-openia-call',
                method: 'POST',
                body: openIAData,
            }),
            invalidatesTags: [{ type: 'StudentSubmissions', id: 'LIST' }],
        }),

        createStudentSubmission: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/student-submissions`,
                method: 'POST',
                body: JSON.stringify(data),
                headers: {'Content-Type': 'application/json'},
            }),
            invalidatesTags: (result, error, { id }) => [
                result === undefined ? [] : { type: 'studentSubmission', id: "ALL" },
                { type: 'StudentCourseWorks', id },
            ],
        }),

        editStudentSubmissionContent: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/api/student-submissions/${id}`,
                method: 'PATCH',
                body: JSON.stringify(data),
                headers: { 'Content-Type': 'application/merge-patch+json' },
            }),
            invalidatesTags: (result) =>
                result === undefined ? [] : [{ type: 'studentSubmission', id: result['@id'] }],
        }),

    }),
});

export const { useLazyGetStudentSubmissionsQuery,useLazyGetStudentSubmissionQuery,useSubmitOpenIAFeedbackMutation, useCreateStudentSubmissionMutation, useEditStudentSubmissionContentMutation } = StudentSubmissions;