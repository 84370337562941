import AppLayout from "../Layout/AppLayout";
import Table from "../../Components/table/Table";
import "./style.css";
import React, { useEffect, useState } from "react";
import { Button, Input } from "antd";
import {
  DownOutlined,
  DownloadOutlined,
  SearchOutlined,
  UpOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { selectClassroom } from "../../redux/app/app.selectors";
import CustomSpin from "../../Components/customSpin/CustomSpin";
import {
  useLazyGetStudentCourseworkByStudentAndClassroomQuery,
  useLazyGetStudentSubmissionPdfQuery,
  useLazyGetStudentSubmissionPracticeModePdfQuery,
} from "../../services/studentcourseworks.api";
import data from "./data";
import PropTypes from 'prop-types'

import {
  selectAuthenticatedUser,
  selectIsAuthenticated,
} from "../../redux/auth/auth.selectors";
import { setNotification } from "../../redux/app/app.slice";
import { Spinner } from "@chakra-ui/react";
import dayjs from "dayjs";
import {log} from "util";

const StudentCourseworkSubmission = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const [dataSorted, setDataSorted] = useState([]);
  const classroom = useSelector(selectClassroom);
  const user = useSelector(selectAuthenticatedUser);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const [
    triggerStudentSubmissions,
    { data: studentSubmissions, isLoading: isLoadingStudentSubmissions },
  ] = useLazyGetStudentCourseworkByStudentAndClassroomQuery();
  const [triggerPDF, { isLoading }] = useLazyGetStudentSubmissionPdfQuery();
  //useLazyGetStudentSubmissionPracticeModePdfQuery
  const [triggerPDFPracticeMode, { isLoading: isLoadingPDFPracticeMode }] =
    useLazyGetStudentSubmissionPracticeModePdfQuery();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const timeConverter = (rawDate) => {
    return moment(rawDate).format("MMM DD YYYY hh:mm");
  };

  useEffect(() => {
    triggerStudentSubmissions({
      studentId: authenticatedUser.id,
      classroomId: classroom.id,
    });
  }, []);

  useEffect(() => {
    if (studentSubmissions)
      setDataSorted(formatedData(studentSubmissions["hydra:member"]));
  }, [studentSubmissions]);

  const formatedData = (data) =>
    data?.map((item) => ({
      id: item.id,
      title: item.coursework.title,
      type: item.coursework.type,
      subject: item.coursework.subject,
      state: item.studentSubmission ? item.studentSubmission.state : "Started",
      created_at: item.coursework.created_at,
      isPracticeMode: item.coursework.isPracticeMode,
      preAssesment: !item.coursework.isPracticeMode
        ? []
        : item.studentSubmission.preAssesmentModePractice?.map(
            (item, index) => ({
              id: item?.id,
              title: `Practice ${index + 1}`,
              created_at: item?.created_at,
              state: item?.state,
            })
          ),
    }));

  const columns = [
    { key: 1, title: "ID", dataIndex: "id" },
    { key: 2, title: "Assignment Title", dataIndex: "title" },
    { key: 3, title: "Format", dataIndex: "type" },
    { key: 4, title: "Subject", dataIndex: "subject" },
    { key: 5, title: "State", dataIndex: "state" },
    { key: 6, title: "Practice Mode", dataIndex: "isPracticeMode" },
    {
      key: 7,
      title: "Date submitted",
      dataIndex: "created_at",
      render: (created_at) => timeConverter(created_at),
    },
    { key: 8, title: "Action", dataIndex: "action" },
  ];

  const handleActionClick = async (id) => {
    setLoading(true);
    const { data, error } = await triggerPDF({
      student_coursework_id: id,
      classroom_id: classroom?.id,
    });
    if (data) {
      const blob = new Blob([data]);
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", `student_submission.pdf`); // Set the download file name

      // Append to the body and click to trigger download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    if (error) {
      dispatch(
        setNotification({
          type: "error",
          message:
            "Error, We encountered an issue while trying to download pdf ,contact support for assistance",
        })
      );
    }
    setLoading(false);
    return data;
  };

  const handleSort = (columnKey) => {
    let direction;
    if (sortConfig.key === columnKey) {
      if (sortConfig.direction === "ascending") {
        direction = null; // Second click, reset to default (unsorted)
      } else if (sortConfig.direction === null) {
        direction = "descending"; // Third click, sort descending
      } else {
        direction = "ascending"; // First click, sort ascending
      }
    } else {
      direction = "ascending"; // New column clicked, sort ascending
    }
    setSortConfig({ key: columnKey, direction });
  };

  const TableHeadCell = (column) => {
    if (column.dataIndex === "id" || column.dataIndex === "action") {
      return column.title;
    } else {
      return (
          <span
            onClick={() => handleSort(column.dataIndex)}
            className={"sort_column"}
          >
            <span>{column.title}</span>
            <span className={"sort__icons"}>
              <UpOutlined
                className={
                  (
                    Array.isArray(sortConfig.key) &&
                    Array.isArray(column.dataIndex)
                      ? sortConfig.direction === "ascending" &&
                        sortConfig.key.join() === column.dataIndex.join()
                      : sortConfig.direction === "ascending" &&
                        sortConfig.key === column.dataIndex
                  )
                    ? "up__icon active"
                    : "up__icon"
                }
              />
              <DownOutlined
                className={
                  (
                    Array.isArray(sortConfig.key) &&
                    Array.isArray(column.dataIndex)
                      ? sortConfig.direction === "descending" &&
                        sortConfig.key.join() === column.dataIndex.join()
                      : sortConfig.direction === "descending" &&
                        sortConfig.key === column.dataIndex
                  )
                    ? "down__icon active"
                    : "down__icon"
                }
              />
            </span>
          </span>
      );
    }
  };

  const TableBodyCell = (column, item) => {
    switch (column.dataIndex) {
      case "isPracticeMode":
        return (
            <span className='tableBody__cell-formatCol'>
              {item[column.dataIndex] ? "Yes" : "No"}
            </span>
        );
      case "action":
        return (
          <Button
            onClick={() => handleActionClick(item.id)}
            className="tableBody__cell-openBtn" style={{textAlign: 'center'}}
            icon={
              loading ? <Spinner width={5} height={5} /> : <DownloadOutlined />
            }
          ></Button>
        );
      case "type":
        if (column.render) {
          return (
            <span className="tableBody__cell-formatCol">
              {column.render(item[column.dataIndex], item)}{" "}
            </span>
          );
        } else {
          return (
            <span className="tableBody__cell-formatCol">
              {item[column.dataIndex]}{" "}
            </span>
          );
        }

      default:
        if (column.render) {
          return column.render(item[column.dataIndex], item);
        } else {
          return item[column.dataIndex];
        }
    }
  };

  const [expanded, setExpanded] = useState([]);


  const RenderRow = ({ item }) => {
    if (!item.isPracticeMode) {
      return (
        <tr>
          {columns.map((column, index) =>
              {
                console.log('column', column, 'item', item);
                return (
                    <td key={index}><div>{TableBodyCell(column, item)}</div></td>
                )
              }
          )}
        </tr>
      );
    }
    return (
      <>
        <tr
          className={
            expanded.includes(item.id)
              ? "expandedTable row-clickable"
              : "row-clickable"
          }
          onClick={() => {
            setExpanded(
              expanded.includes(item.id)
                ? expanded.filter((id) => id !== item.id)
                : [...expanded, item.id]
            );
          }}
        >
          {columns.map((column, index) => {
            return (
                <td key={index} className={"w-max-content"}>
                  <div>
                    {index !== columns.length - 1 ? (
                        TableBodyCell(column, item)
                    ) : expanded ? (
                        <button style={{display: "block", width: "30px", textAlign: "center"}}><DownOutlined/></button>
                    ) : (
                        <button style={{display: "block", width: "30px", textAlign: "center"}}><DownOutlined/></button>
                    )}
                  < /div>
                </td>
          );
          })}
        </tr>
        {expanded.includes(item.id) && (
          <tr className={expanded ? "expandedTable" : ""}>
            <td colSpan={columns.length}>
              <div className={"sub-table"}>
                <div className={"sub-table-header-item"}>
                  <div className={"sub-table-title-item"}>Title</div>
                  <div className={"sub-table-title-item"}>State</div>
                  <div className={"sub-table-title-item"}>Date submitted</div>
                  <div className={"sub-table-title-item"}>Action</div>
                </div>

                {item?.preAssesment?.map((subItem) => (
                  <div
                    className={"sub-table-row-item"}
                    key={`sub-item${subItem.id}`}
                  >
                    <div className={"sub-table-body-item"}>
                      {subItem?.title}
                    </div>
                    <div className={"sub-table-body-item"}>
                      {subItem?.state}
                    </div>
                    <div className={"sub-table-body-item"}>
                      {dayjs(subItem.created_at).format("MMM DD YYYY HH:mm")}
                    </div>
                    <div className={"sub-table-body-item"}>
                      <Button
                        className="tableBody__cell-openBtn"
                        onClick={async (e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          const { data, error } = await triggerPDFPracticeMode({
                            student_coursework_id: item.id,
                            classroom_id: classroom?.id,
                            pre_assesment_id: subItem.id,
                          });

                          if(subItem?.state === "completed") {

                            if (data) {
                              const blob = new Blob([data]);
                              const link = document.createElement("a");
                              link.href = window.URL.createObjectURL(blob);
                              const timestampMs = Date.now();
                              link.setAttribute(
                                  "download",
                                  `student_submission-${timestampMs}.pdf`
                              );

                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                            }
                            if (error) {
                              dispatch(
                                  setNotification({
                                    type: "error",
                                    message:
                                        "Error, We encountered an issue while trying to download pdf ,contact support for assistance",
                                  })
                              );
                            }
                            return data;
                          } else {
                            dispatch(
                                setNotification({
                                  type: "info",
                                  message:
                                      "coursework not completed",
                                })
                            );
                          }
                        }}
                        icon={<DownloadOutlined />}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  // useEffect(() => {
  //   if (!sortConfig){
  //     setDataSorted(formatedData(studentSubmissions));
  //   }
  //   else {
  //     const studentSubmissionsData = formatedData(studentSubmissions) || [];
  //     const sortedData = [...studentSubmissionsData]?.sort((a, b) => {
  //       if (sortConfig.direction === 'ascending') {
  //         return a?.[sortConfig?.key] > b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       if(sortConfig.direction === 'descending'){
  //         return a?.[sortConfig?.key] < b?.[sortConfig?.key] ? 1 : -1;
  //       }
  //       return 0;
  //     })
  //     setDataSorted(sortedData)
  //   }
  // }, [sortConfig, studentSubmissions]);

  if (isLoadingStudentSubmissions) {
    return <CustomSpin />;
  }
  return (
    <AppLayout hideHeader={true}>
      <div className="content__submissions content__submissions__student">
        <div className={"submission__top-page"}>
          <div className="submissions__filter">
            <span> Students Submissions</span>
          </div>

          <div className="submissions-new">
            <Input
              type="text"
              placeholder="search"
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <Table
          columns={columns}
          data={dataSorted}
          TableHeadCell={TableHeadCell}
          expanable={(item) => <RenderRow item={item} />}
          noDataText={`You haven't added any submission yet.`}
          searchTerm={searchTerm}
          isLoading={isLoadingStudentSubmissions}
        />
      </div>
    </AppLayout>
  );
};

export default StudentCourseworkSubmission;
