
import { Layout,Button,  Avatar, Tooltip } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, LogoutOutlined} from "@ant-design/icons";
import remediaLogo from "../../assets/images/remedia-logo.png"
import avatar from "../../assets/images/Avatar.svg";
import MenuList from "../LeftSideMenu/LeftSideMenu.jsx";
import { Link } from "react-router-dom";
import './LeftSideBar.css';
import {useGetMeQuery} from "../../services/auth.api";

import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {logout} from "../../redux/auth/auth.slice";


const { Sider } = Layout;


const text = (
    <div className="sidebar__footerMenu-info">
        <span className="sidebar__footerMenu-info-name">User name</span>
        <span className="sidebar__footerMenu-info-role">Teacher</span>
    </div>
);


const LeftSideBar = ({handlSidebarCollapse , collapsed, hideClass}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _logout =()=>{
        dispatch(logout);
        navigate('/sign-in')}
    const { data: authenticatedUser, error, isLoading } = useGetMeQuery();
    let updatedRoles = [];
    if(authenticatedUser)
    {
         updatedRoles = authenticatedUser.roles.map(role => role.replace('ROLE_', '')).join(', ');

    }
    // verify if the user is a teacher
    if(authenticatedUser.roles.includes('ROLE_TEACHER')){
        updatedRoles = 'Teacher';
    }else{
        updatedRoles = 'Student';
    }

    console.log('authenticatedUser', authenticatedUser);
    return (
        <div className={collapsed ? `sidebar sidebarCollapsed` : `sidebar`}>

            <div className="sidebar__headerMenu">
                <Link to={"/"}>
                    <img src={remediaLogo} alt="remedia logo" width={95}
                         className={collapsed ? `sidebar__headerMenu-LogoCollapsed` : `sidebar__headerMenu-Logo`}
                    />
                </Link>
                <Button type="default" onClick={handlSidebarCollapse}>
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
            </div>

            <Sider className="sidebar__menuList" collapsed={collapsed} collapsible>
                <MenuList collapsed={collapsed} hideClass={hideClass} />
            </Sider>

            <div className="sidebar__footerMenu">
                {collapsed ? (
                    <Tooltip placement="left" title={text} className="avatar-tooltip">
                        <Avatar src={avatar} />
                    </Tooltip>
                ) : (
                    <>
                        <Avatar src={avatar} />
                        {authenticatedUser ?
                            (
                                <div className="sidebar__footerMenu-info">
                                    <span
                                        className="sidebar__footerMenu-info-name">{authenticatedUser.firstName} {authenticatedUser.familyName}</span>
                                    <span className="sidebar__footerMenu-info-role">{updatedRoles}</span>
                                </div>

                            ) : (<div className="sidebar__footerMenu-info">
                                <span className="sidebar__footerMenu-info-name">User name</span>
                                <span className="sidebar__footerMenu-info-role">Teacher</span>
                            </div>)
                        }
                    </>
                )}
            </div>
            <div className={"logout"}>
                <Button type="default" className="sidebar__footerMenu-logoutBtn" icon={<LogoutOutlined/>} onClick={_logout}></Button>
            </div>

        </div>
    );
}

export default LeftSideBar