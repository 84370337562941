import React from "react";
import MenuSlate from "./menuSlate";

const ToolbarSlate = React.forwardRef(({className, ...props}, ref) => (
    <MenuSlate
        {...props}
        ref={ref}
        className={className}
        style={{
            position: "relative",
            padding: "1px 18px 17px",
            margin: "0 -20px",
            borderBottom: "2px solid #eee",
            marginBottom: "20px",
        }}
    />
))

export default ToolbarSlate;

