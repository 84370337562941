import { api } from './api';

export const authApi = api.injectEndpoints({
    endpoints: (builder) => ({

         createAssignment: builder.mutation({
             query: (formData) => ({
                 url: '/api/courseworks',
                 method: 'POST',
                 body: JSON.stringify(formData),
                 headers: {'Content-Type': 'application/json'},
             }),
                invalidatesTags: [{type: 'Assignments', id: 'ALL'}],
         }),

        updateAssignment: builder.mutation({
                query: ({ formData,id,image }) => {
                    const body = new FormData();
                    body.append('file', image);
                    body.append('data',JSON.stringify(formData))

                    return {
                        url: `/api/course-works-test/${id}`,
                        method: 'POST',
                        body,
                    }
                },
            invalidatesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'Assignments', id: 'ALL' },
                    ],
        }),


        getAssignments: builder.query({
            query: () => ({
                url: '/api/courseworks',
                method: 'GET',
            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        {type: 'Assignments', id: 'ALL'},
                    ],
        }),
        getAssignmentByFilter: builder.query({
            query: (filter) => `/api/courseworks?title=${filter}&subject=${filter}&description=${filter}&grade=${filter}`,
        }),

        getAssignmentsByTeacherAndClass: builder.query({
            query: (data) => ({
                params: data,
                url: `/api/get-course-work`,
                method: 'GET',
            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [{ type: 'AssignmentsFilter', id: 'LIST' }],
        }),


    }),
});

export const { useCreateAssignmentMutation, useLazyGetAssignmentsQuery , useGetAssignmentByFilterQuery, useUpdateAssignmentMutation,useLazyGetAssignmentsByTeacherAndClassQuery  } = authApi;