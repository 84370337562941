import { Button,Modal} from 'antd';
import ViewRubricTable from '../viewRubricTable/ViewRubricTable';
import React, {useEffect} from "react";
import {ImportOutlined} from "@ant-design/icons";

const ViewRubricModal = ({isViewModalOpen, setIsViewModalOpen, rubricItem, rubricItemData,importRubric}) => {
    const handleImportClick = async (rubric) => {
        importRubric(rubricItemData);
        // close the modal after 5 seconds
        setTimeout(() => {
            setIsViewModalOpen(!isViewModalOpen)
        }, 1000);

    };
    const handleCancel=()=>{
        setIsViewModalOpen(!isViewModalOpen)
    }

    console.log('rubricItem =>', rubricItem);
    console.log('rubricData =>', rubricItemData);

    return (
        <Modal
            title={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <span>{rubricItemData?.title}</span>
                    {rubricItemData && rubricItemData['@type'] === "RubricStandard" ? (
                        <Button style={{marginRight: "3%", display: "flex", alignItems: "center"}} onClick={() => handleImportClick(rubricItemData)}
                                className='tableBody__cell-openBtn'>
                            Import Rubric <ImportOutlined/>
                        </Button>
                    ) : null}
                </div>
            }
            open={isViewModalOpen} onCancel={handleCancel} footer={null} width={920} style={{top: 38}}>
            {rubricItemData?.criterias.map((item, index) => (
                <ViewRubricTable key={index} criteria={item}/>
            ))}
        </Modal>
    )
}

export default ViewRubricModal