import LeftArrow from "../../assets/images/left-arrow.svg"
import { Input, Button } from "antd";
import "./forgetPassword.css"

const ForgetPassword = () => {
    const submitPassword = () => {
        alert("Password recovery code sent")
    }
  return (
    <div className="container">
      <div className="passwordRecovery">
        <h1 className="passwordRecovery__title">Account recovery</h1>
        <span className="passwordRecovery__subTitle">
           <p> Enter the email address you used when you joined,
            and we'll send you instructions to reset your password.</p>
        </span>
        <form action="" className="passwordRecovery__form" >
            <Input placeholder="Alexanderdoe@email.com" size="large" type="email" className="passwordRecovery__form-email" />
            <Button type="primary" block className="passwordRecovery__form-btn">
                Send validation code
            </Button>
        </form>
        <a href="/sign-in" className="passwordRecovery__backToLogin">
            <img src={LeftArrow} alt="left arrow icon" width="20px"/>
            <span>Back to sign in</span>
        </a>
      </div>
    </div>
  )
}

export default ForgetPassword
