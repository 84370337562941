
const TextAlignRight = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.571411 1.5C0.571411 0.947715 1.01913 0.5 1.57141 0.5H17.5714C18.1237 0.5 18.5714 0.947715 18.5714 1.5V1.5C18.5714 2.05228 18.1237 2.5 17.5714 2.5H1.57141C1.01913 2.5 0.571411 2.05228 0.571411 1.5V1.5ZM4.57141 16.5C4.57141 15.9477 5.01913 15.5 5.57141 15.5H17.5714C18.1237 15.5 18.5714 15.9477 18.5714 16.5V16.5C18.5714 17.0523 18.1237 17.5 17.5714 17.5H5.57141C5.01913 17.5 4.57141 17.0523 4.57141 16.5V16.5ZM0.571411 11.5C0.571411 10.9477 1.01913 10.5 1.57141 10.5H17.5714C18.1237 10.5 18.5714 10.9477 18.5714 11.5V11.5C18.5714 12.0523 18.1237 12.5 17.5714 12.5H1.57141C1.01913 12.5 0.571411 12.0523 0.571411 11.5V11.5ZM4.57141 6.5C4.57141 5.94772 5.01913 5.5 5.57141 5.5H17.5714C18.1237 5.5 18.5714 5.94772 18.5714 6.5V6.5C18.5714 7.05228 18.1237 7.5 17.5714 7.5H5.57141C5.01913 7.5 4.57141 7.05228 4.57141 6.5V6.5Z"
                fill="currentColor"/>
        </svg>
    )
}

export default TextAlignRight;