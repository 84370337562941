import React, {useEffect, useMemo, useState} from 'react';
import { Modal, Button, Row, Col, Card, Typography, Table, Progress,Tag } from 'antd';
import { BarChartOutlined } from '@ant-design/icons';
import Chart from 'react-apexcharts';
import { LineChart } from '@mui/x-charts';
import '../../App.css'
import './StatisticsModal.css';
import PieChart from "./PieChartData";
import {useSelector} from "react-redux"; // Assuming you have a CSS file for custom styles

const { Text } = Typography;

const StatisticsModal = ({ visible, handleCloseModal, dataStatistic }) => {
    const [visibled, setVisibled] = useState(false);
    const correction = dataStatistic || [];
    const [radarChartOptions, setRadarChartOptions] = useState(null);
    const [columnChartOptions, setColumnChartOptions] = useState(null);
    const [totalWords, setTotalWords] = useState(0);
    const [uniqueWords, setUniqueWords] = useState(0);
    const [numberOfLongWords, setNumberOfLongWords] = useState(0);
    const [numberOfComplexWords, setNumberOfComplexWords] = useState(0);
    const [difficultWords, setDifficultWords] = useState(0);
    const [numberOfToBeVerbs, setNumberOfToBeVerbs] = useState(0);
    const [numberOfOxiliaryVerbs, setNumberOfOxiliaryVerbs] = useState(0);
    const [numberOfConjunctions, setNumberOfConjunctions] = useState(0);
    const [numberOfPronouns, setNumberOfPronouns] = useState(0);
    const [numberOfPrepositions, setNumberOfPrepositions] = useState(0);
    const [numberOfNominalizations, setNumberOfNominalizations] = useState(0);
    const [numberOfSenences, setNumberOfSenences] = useState(0);
    const [averageNumberOfWordsPerSentence, setAverageNumberOfWordsPerSentence] = useState(0);
    const [averageNumberOfSyllablesPerWord, setAverageNumberOfSyllablesPerWord] = useState(0);
    const [percentageOfSentencesBeginWithPronoun, setPercentageOfSentencesBeginWithPronoun] = useState(0);
    const [percentageOfSentencesBeginWithInterrogative, setPercentageOfSentencesBeginWithInterrogative] = useState(0);
    const [percentageOfSentencesBeginWithArticle, setPercentageOfSentencesBeginWithArticle] = useState(0);
    const [percentageOfSentencesBeginWithSubordination, setPercentageOfSentencesBeginWithSubordination] = useState(0);
    const [percentageOfSentencesBeginWithConjunction, setPercentageOfSentencesBeginWithConjunction] = useState(0);
    const [percentageOfSentencesBeginWithPreposition, setPercentageOfSentencesBeginWithPreposition] = useState(0);

    let errorTypes=dataStatistic || []
    let wordTypes=dataStatistic ||  []
    let num_errors = 0
    let totalErrors= 0
    let ratioPaper = 0
    if(correction && correction.num_words){
        num_errors = correction.num_errors.reduce((sum, error) => sum + error, 0);
    }
    if(correction && correction.error_composition && correction.error_composition.length>0){
        errorTypes = correction.error_composition[0]
        wordTypes = correction.error_composition[1]
        totalErrors = Object.values(errorTypes).reduce((a, b) => a + b, 0);
        ratioPaper= ((totalErrors / num_errors) * 100);
        if(ratioPaper > 0){
            ratioPaper=ratioPaper.toFixed(2)
        }
    }

    useEffect(() => {
        console.log("****dataStatistic",dataStatistic)
        if (dataStatistic && visible) {
            // setLexicalDiversity(((dataStatistic?.lexical_error?.num_uniquewords)/(dataStatistic?.lexical_error?.results['sentence info']?.wordtypes))*100);
            setTotalWords(dataStatistic?.lexical_error?.results['sentence info']?.words);
            setUniqueWords(dataStatistic?.lexical_error?.num_uniquewords);
            setNumberOfLongWords(dataStatistic?.lexical_error?.results['sentence info']?.long_words);
            setNumberOfComplexWords(dataStatistic?.lexical_error?.complex_words);
            setDifficultWords(dataStatistic?.lexical_error?.results['sentence info']?.complex_words_dc);
            setNumberOfToBeVerbs(dataStatistic?.lexical_error?.results['word usage']?.tobeverb);
            setNumberOfOxiliaryVerbs(dataStatistic?.lexical_error?.results['word usage']?.auxverb);
            setNumberOfConjunctions(dataStatistic?.lexical_error?.results['word usage']?.conjunction);
            setNumberOfPronouns(dataStatistic?.lexical_error?.results['word usage']?.pronoun);
            setNumberOfPrepositions(dataStatistic?.lexical_error?.results['word usage']?.preposition);
            setNumberOfNominalizations(dataStatistic?.lexical_error?.results['word usage']?.nominalization);
            setNumberOfSenences(dataStatistic?.lexical_error?.results['sentence info']?.sentences);
            setAverageNumberOfWordsPerSentence(dataStatistic?.lexical_error?.results['sentence info']?.words_per_sentence);
            setAverageNumberOfSyllablesPerWord(dataStatistic?.lexical_error?.results['sentence info']?.syll_per_word);
            setPercentageOfSentencesBeginWithPronoun(dataStatistic?.lexical_error?.results['sentence beginnings']?.pronoun);
            setPercentageOfSentencesBeginWithInterrogative(dataStatistic?.lexical_error?.results['sentence beginnings']?.interrogative);
            setPercentageOfSentencesBeginWithArticle(dataStatistic?.lexical_error?.results['sentence beginnings']?.article);
            setPercentageOfSentencesBeginWithSubordination(dataStatistic?.lexical_error?.results['sentence beginnings']?.subordination);
            setPercentageOfSentencesBeginWithConjunction(dataStatistic?.lexical_error?.results['sentence beginnings']?.conjunction);
            setPercentageOfSentencesBeginWithPreposition(dataStatistic?.lexical_error?.results['sentence beginnings']?.preposition);

        }

    }, [dataStatistic,visible]);

    console.log('statistic data::', dataStatistic)

    const ratioCalculate = (ratio, value) => {
        return  (ratio*value).toFixed(2)
    };

    useEffect(() => {
        setVisibled(visible);
        if(visible){
            const radarData = {
                series: [{
                    name: 'Text Analysis',
                    data: [
                        totalWords,
                        uniqueWords,
                        numberOfLongWords,
                        numberOfComplexWords,
                        difficultWords
                    ]
                }],
                options: {
                    chart: {
                        height: 350,
                        type: 'radar',
                    },
                    title: {
                        text: 'Lexical diversity'
                    },
                    xaxis: {
                        categories: [
                            "Total Words",
                            "Unique words",
                            "Long Words",
                            "Complex Words",
                            "Difficult words"
                        ]
                    }
                }
            };
            setRadarChartOptions(radarData);

            const columnChart = {
                series: [{
                    name: 'Text Analysis',
                    data: [
                        numberOfToBeVerbs,
                        numberOfOxiliaryVerbs,
                        numberOfConjunctions,
                        numberOfPronouns,
                        numberOfPrepositions,
                        numberOfNominalizations
                    ]
                }],
                options: {
                    chart: {
                        type: 'bar',
                        height: 500,
                    },
                    title: {
                        text: 'Grammatical structure'
                    },
                    xaxis: {
                        categories: [
                            "To be verbs",
                            "Auxiliary verbs",
                            "Conjunctions",
                            "Pronouns",
                            "Prepositions",
                            "Nominalizations"
                        ]
                    }
                }
            };

            setColumnChartOptions(columnChart);
        }
    }, [
        visible,
        totalWords,
        uniqueWords,
        numberOfLongWords,
        numberOfComplexWords,
        difficultWords,
        numberOfToBeVerbs,
        numberOfOxiliaryVerbs,
        numberOfConjunctions,
        numberOfPronouns,
        numberOfPrepositions,
        numberOfNominalizations

    ]);

    useEffect(() => {

    }, [dataStatistic]);

    const getErrorPercentage = (type) => {
        const percent = (errorTypes[type] / totalErrors) * 100; // Calculate the percentage
        return  {"percent": Math.round(percent * 10)/10 || 0, "value": errorTypes[type]}
    };

    const data = [
        { color: '#FF6633', type: 'Adjective'},
        { color: '#E6B333',  type: 'Adjective form' },
        { color: '#80B300',  type: 'Adverb' },
        { color: '#FF99E6', type: 'Conjunction' },
        { color: '#66994D', type: 'Contraction' },
        { color: '#66664D',  type: 'Determiner' },
        { color: '#FFB399', type: 'Morphology' },
        { color: '#3366E6',  type: 'Noun' },
        { color: '#809900',  type: 'Noun Number' },
        { color: '#CCFF1A', type: 'Noun Possessive' },
        { color: '#B366CC',  type: 'Noun inflection' },
        { color: '#559f97', type: 'Orthography' },
        { color: '#a84b4b',  type: 'Other' },
        { color: '#999966', type: 'Particle' },
        { color: '#E6B3B3',  type: 'Preposition' },
        { color: '#FF1A66',  type: 'Pronoun' },
        { color: '#4D8000',  type: 'Punctuation' },
        { color: '#E666FF',  type: 'Spelling' },
        { color: '#adad46',  type: 'Subject-Verb Agreement' },
        { color: '#99FF99',  type: 'Unknown' },
        { color: '#6680B3',  type: 'Verb' },
        { color: '#E6331A',  type: 'Verb Form' },
        { color: '#B33300',  type: 'Verb Inflection' },
        { color: '#4DB3FF',  type: 'Verb Tense' },
        { color: '#b0ae3c',type: 'Word Order' },
    ];

    const dataSyntacticComplexity = [
        { color: '#FF6633', type: 'Number of sentences', value: numberOfSenences },
        { color: '#E6B333',  type: 'Words per Sentence', value: averageNumberOfWordsPerSentence },
        { color: '#80B300',  type: 'Syllables per Word', value: averageNumberOfSyllablesPerWord },
        { color: '#FF99E6', type: 'Sentences beginning with a pronoun' , value: percentageOfSentencesBeginWithPronoun },
        { color: '#66994D', type: 'Sentences beginning with an  interrogative' , value: percentageOfSentencesBeginWithInterrogative },
        { color: '#66664D',  type: 'Sentences beginning with an article' , value: percentageOfSentencesBeginWithArticle },
        { color: '#FFB399', type: 'Sentences beginning with a subordination' , value: percentageOfSentencesBeginWithSubordination },
        { color: '#3366E6',  type: 'Sentences beginning with a conjunction' , value: percentageOfSentencesBeginWithConjunction },
        { color: '#809900',  type: 'Sentences beginning with a preposition' , value: percentageOfSentencesBeginWithPreposition },
    ]

    console.log('dataSyntacticComplexity', dataSyntacticComplexity)

    const columnsSyntacticComplexity = [
        {
            title: 'Title',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (text, record) => {
                const value =  Math.round(text * 10)/10
                return (
                    <Tag color={record.color}>
                        {`${value}`}
                    </Tag>
                );
            },
        },
    ];

    return (

        <Modal
            title="Linguistic statistics"
            visible={visibled}
            onCancel={handleCloseModal}
            className="modal-statistics"
            footer={[
                <Button key="back" onClick={handleCloseModal}>
                    Close
                </Button>,
                <Button key="export" type="primary" icon={<BarChartOutlined />}>
                    Export Statistics
                </Button>
            ]}
            width="100%"
            style={{ top: 0 }}
            bodyStyle={{ height: 'calc(100vh - 55px)', overflow: 'auto', padding: '20px' }}
        >
            <div className="content-wrapper">
                <Card className="statistics-card-circles">
                    <div>
                        <h5 className={"title-statistics-bloc"}>Types of Linguistic Errors</h5>
                    </div>
                    <div className="statistics-circles">
                        {data
                            ?.sort((a, b) => getErrorPercentage(b?.type)["value"] - getErrorPercentage(a?.type)["value"]) // Sort by value in descending order
                            .map((item, index) => (
                                getErrorPercentage(item?.type)["value"] > 0 && (
                                    <div key={index} className="stat-card">
                                        <div className="progress-wrapper">
                                            <Progress
                                                type="circle"
                                                percent={getErrorPercentage(item?.type)['percent']}
                                                size={40}
                                                strokeColor={item?.color}
                                                format={() => `${getErrorPercentage(item?.type)["value"]}`}
                                            />
                                        </div>
                                        <div className="text-wrapper">
                                            <div className="circle-title">{item?.type}</div>
                                            <div className="circle-text">{item?.text}</div>
                                        </div>
                                    </div>
                                )
                            ))}
                    </div>
                </Card>
                <Card className="statistics-card">
                    <div>
                        <h5 className={"title-statistics-bloc"}>Syntactic complexity</h5>
                    </div>
                    <div className="statistics-table">
                        <Table className={"table-errors"} dataSource={dataSyntacticComplexity} columns={columnsSyntacticComplexity}
                               pagination={false}/>
                    </div>
                </Card>
            </div>
            <div className=" statistics-word-error" style={{marginTop: 20}}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Card>
                            {
                                visible && radarChartOptions &&(
                                    <Chart
                                        options={radarChartOptions?.options}
                                        series={radarChartOptions?.series}
                                        type={"radar"}
                                        height={500}
                                    />
                                )
                            }
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className={"chart-line"}>
                            {
                                visible && columnChartOptions &&(
                                    <Chart
                                        options={columnChartOptions?.options}
                                        series={columnChartOptions?.series}
                                        type={"bar"}
                                        height={500}
                                    />
                                )
                            }
                        </Card>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};

export default StatisticsModal;