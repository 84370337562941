import { api } from './api';

export const RubricStandard = api.injectEndpoints({
    endpoints: (builder) => ({

        getRubricsStandard: builder.query({
            query: () => ({
                url: '/api/rubric-standards',
                method: 'GET',

            }),
            providesTags: (result) =>
                result === undefined
                    ? []
                    : [
                        { type: 'RubricsStandard', id: 'LIST' },
                    ],
        }),
        getRubricStandardById: builder.query({
            query: (id) => `/api/rubric-standards/${id}`,
        }),
        createRubricStandard: builder.mutation({
            query: (rubricData) => {
                return {
                    url: '/api/rubric-standards',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },

                    body: JSON.stringify(rubricData),
                }
            },
            invalidatesTags: [
                { type: 'RubricsStandard', id: 'LIST' },
            ],
        }),

    }),
});

export const { useLazyGetRubricsStandardQuery,useLazyGetRubricStandardByIdQuery,useCreateRubricStandardMutation } = RubricStandard;