import React from "react";

const IconSlate = React.forwardRef(({className, icon, ...props }, ref) => {
    return (
        <>
            {icon}
        </>

    )
})

export default IconSlate;