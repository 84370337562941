import { Progress } from 'antd';
import "./analyticsProgress.css";


const AnalyticsProgress = ({ label, percentage , progressColor="#000095" }) => {
  return (

    <div className="analytics-item">
      {
        label === "Syntactic complexity" || label === "Readability Score" ? (
                <>
                  <strong className="analytics-percentage" style={{color: progressColor}}>
                    {percentage}
                  </strong>
                  <span className="analytics-label" style={{color: progressColor}}>{label}</span>
                </>
            )
            : (
                <>
                  <strong className="analytics-percentage" style={{color: progressColor}}>
                    {percentage}%
                  </strong>
                  <span className="analytics-label" style={{color: progressColor}}>{label}</span>
                </>
            )
      }
    </div>
  );
};

export default AnalyticsProgress;
