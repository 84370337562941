import React from "react";

const JustifyIcon = () => {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.857147 1.5C0.857147 0.947715 1.30486 0.5 1.85715 0.5H17.8571C18.4094 0.5 18.8571 0.947715 18.8571 1.5V1.5C18.8571 2.05228 18.4094 2.5 17.8571 2.5H1.85715C1.30486 2.5 0.857147 2.05228 0.857147 1.5V1.5ZM0.857147 16.5C0.857147 15.9477 1.30486 15.5 1.85715 15.5H17.8571C18.4094 15.5 18.8571 15.9477 18.8571 16.5V16.5C18.8571 17.0523 18.4094 17.5 17.8571 17.5H1.85715C1.30486 17.5 0.857147 17.0523 0.857147 16.5V16.5ZM0.857147 11.5C0.857147 10.9477 1.30486 10.5 1.85715 10.5H17.8571C18.4094 10.5 18.8571 10.9477 18.8571 11.5V11.5C18.8571 12.0523 18.4094 12.5 17.8571 12.5H1.85715C1.30486 12.5 0.857147 12.0523 0.857147 11.5V11.5ZM0.857147 6.5C0.857147 5.94772 1.30486 5.5 1.85715 5.5H17.8571C18.4094 5.5 18.8571 5.94772 18.8571 6.5V6.5C18.8571 7.05228 18.4094 7.5 17.8571 7.5H1.85715C1.30486 7.5 0.857147 7.05228 0.857147 6.5V6.5Z"
                fill="currentColor"/>
        </svg>
    )
}

export default JustifyIcon;