
const TextAlignLeft = () => {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.285706 1.5C0.285706 0.947715 0.733421 0.5 1.28571 0.5H14.7143C15.2666 0.5 15.7143 0.947715 15.7143 1.5V1.5C15.7143 2.05228 15.2666 2.5 14.7143 2.5H1.28571C0.733422 2.5 0.285706 2.05228 0.285706 1.5V1.5ZM0.285706 16.5C0.285706 15.9477 0.733421 15.5 1.28571 15.5H9.57142C10.1237 15.5 10.5714 15.9477 10.5714 16.5V16.5C10.5714 17.0523 10.1237 17.5 9.57142 17.5H1.28571C0.733422 17.5 0.285706 17.0523 0.285706 16.5V16.5ZM0.285706 11.5C0.285706 10.9477 0.733421 10.5 1.28571 10.5H14.7143C15.2666 10.5 15.7143 10.9477 15.7143 11.5V11.5C15.7143 12.0523 15.2666 12.5 14.7143 12.5H1.28571C0.733422 12.5 0.285706 12.0523 0.285706 11.5V11.5ZM0.285706 6.5C0.285706 5.94772 0.733421 5.5 1.28571 5.5H9.57142C10.1237 5.5 10.5714 5.94772 10.5714 6.5V6.5C10.5714 7.05228 10.1237 7.5 9.57142 7.5H1.28571C0.733422 7.5 0.285706 7.05228 0.285706 6.5V6.5Z"
                fill="currentColor"/>
        </svg>
    )
}

export default TextAlignLeft;