import React from "react";

const  Italic = () => {

    return (
        <svg height="18" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.76 4H4.76L2.56 14H0.559998L2.76 4ZM4.44 0C4.24222 0 4.04888 0.0586491 3.88443 0.16853C3.71998 0.278412 3.59181 0.434591 3.51612 0.617317C3.44043 0.800043 3.42063 1.00111 3.45921 1.19509C3.4978 1.38907 3.59304 1.56725 3.73289 1.70711C3.87274 1.84696 4.05093 1.9422 4.24491 1.98079C4.43889 2.01937 4.63995 1.99957 4.82268 1.92388C5.00541 1.84819 5.16159 1.72002 5.27147 1.55557C5.38135 1.39112 5.44 1.19778 5.44 1C5.44 0.734784 5.33464 0.48043 5.1471 0.292893C4.95957 0.105357 4.70521 0 4.44 0Z"
                fill="currentColor"/>
        </svg>
    )
}

export default Italic;