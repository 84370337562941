import React from 'react';
import { Modal, Input, Select } from 'antd';
import { Formik } from 'formik';

const ClassModal = ({ isModalOpen, handleCancel, handleSubmit, mode, initialValues }) => {
    const isEditMode = mode === 'edit';

    return (
        <Modal open={isModalOpen} onCancel={handleCancel} centered footer={null} width={685}>
            <div className="createClassModal__header">
                <h1>{isEditMode ? 'Edit Class' : 'Create Class'}</h1>
                <h2>
                    You can enroll students by sharing the course code or invite link from the Classes
                    tab. Each class will have its related assignments, rubrics, and a section for submitted work.
                </h2>
            </div>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    handleSubmit(values);
                }}
                enableReinitialize={true}
            >
                {({ values, setFieldValue, handleChange, handleSubmit }) => (
                    <form className="createClassModal__bottom" onSubmit={handleSubmit}>
                        <div className="createClassModal__bottom-classTitle">
                            <label htmlFor="classTitle">Class Name *</label>
                            <Input
                                name="name"
                                value={values.name}
                                placeholder="Enter your class name"
                                id="name"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="createClassModal__bottom-SubjectGrade">
                            <div className="createClassModal__bottom-SubjectGrade-subject">
                                <label>
                                    <span>Class Subject *</span>
                                    <span>Select a subject</span>
                                </label>
                                <Select
                                    name="subject"
                                    value={values.subject}
                                    onChange={value => setFieldValue('subject', value)}
                                    options={[
                                        { value: 'Math', label: 'Math' },
                                        { value: 'English', label: 'English' },
                                        { value: 'Science', label: 'Science' },
                                        { value: 'History', label: 'History' },
                                        { value: 'Geography', label: 'Geography' },
                                    ]}
                                />
                            </div>
                            <div className="createClassModal__bottom-SubjectGrade-grade">
                                <label>
                                    <span>Grade *</span>
                                    <span>Select a grade</span>
                                </label>
                                <Select
                                    name="grade"
                                    value={values.grade}
                                    onChange={value => setFieldValue('grade', value)}
                                    options={[
                                        { value: '1st', label: '1st' },
                                        { value: '2nd', label: '2nd' },
                                        { value: '3rd', label: '3rd' },
                                        { value: '4th', label: '4th' },
                                        { value: '5th', label: '5th' },
                                        { value: '6th', label: '6th' },
                                        { value: '7th', label: '7th' },
                                        { value: '8th', label: '8th' },
                                        { value: '9th', label: '9th' },
                                        { value: '10th', label: '10th' },
                                        { value: '11th', label: '11th' },
                                    ]}
                                />
                            </div>
                        </div>
                        <button type="submit" className="createClassBtn">
                            {isEditMode ? 'Save Changes' : 'Create'}
                        </button>
                    </form>
                )}
            </Formik>
        </Modal>
    );
};

export default ClassModal;