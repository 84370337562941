import deleteYellowIcon from "../../assets/images/yellowDelete.svg";
import { Input, InputNumber } from "antd";
import "./criteriaScore.css"

const { TextArea } = Input;


const CriteriaScore = ({ score, onScoreChange, onDeleteScore, error }) => {
    return (
        <div className="criteriaScore">
            <div className="levelsBoxTitle">Level</div>
            <div className="levelsBox">
                <div className="levelsBox-title">
                    <Input
                        className="criteriaLevel"
                        placeholder="Enter level"
                        value={score.title}
                        onChange={(e) => onScoreChange('title', e.target.value)}
                    />
                </div>
                <div className="levelsBox-point">
                    <InputNumber
                        min={0}
                        className="criteriaPoint"
                        placeholder="Enter score"
                        name={score.point}
                        value={score.point}
                        onChange={(value) => onScoreChange('point', value)}
                    />
                    {error && <div className="errorMessage">{error.point}</div>}
                </div>
            </div>
            <TextArea
                className="description"
                placeholder="What would earn this score?"
                value={score.description}
                onChange={(e) => onScoreChange('description', e.target.value)}
            />
            {error && <div className="errorMessage">{error.description}</div>}
            <button className="deleteCriteriaScore"  type="button" onClick={onDeleteScore}>
                <img src={deleteYellowIcon} width={15} alt="delete icon" /> delete
            </button>
        </div>
    );
}

export default CriteriaScore
