

const TextAlignCenter = () => {
    return (
        <svg width="17" height="18" viewBox="0 0 17 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.285706 1.5C0.285706 0.947715 0.733421 0.5 1.28571 0.5H15.2857C15.838 0.5 16.2857 0.947715 16.2857 1.5V1.5C16.2857 2.05228 15.838 2.5 15.2857 2.5H1.2857C0.73342 2.5 0.285706 2.05228 0.285706 1.5V1.5ZM3.28571 16.5C3.28571 15.9477 3.73342 15.5 4.28571 15.5H12.2857C12.838 15.5 13.2857 15.9477 13.2857 16.5V16.5C13.2857 17.0523 12.838 17.5 12.2857 17.5H4.28571C3.73342 17.5 3.28571 17.0523 3.28571 16.5V16.5ZM3.28571 6.5C3.28571 5.94772 3.73342 5.5 4.28571 5.5H12.2857C12.838 5.5 13.2857 5.94772 13.2857 6.5V6.5C13.2857 7.05228 12.838 7.5 12.2857 7.5H4.28571C3.73342 7.5 3.28571 7.05228 3.28571 6.5V6.5ZM0.285706 11.5C0.285706 10.9477 0.733421 10.5 1.28571 10.5H15.2857C15.838 10.5 16.2857 10.9477 16.2857 11.5V11.5C16.2857 12.0523 15.838 12.5 15.2857 12.5H1.2857C0.73342 12.5 0.285706 12.0523 0.285706 11.5V11.5Z"
                fill="currentColor"/>
        </svg>
    )
}

export default TextAlignCenter;